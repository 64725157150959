import React, { useState, useEffect } from "react";
import { BsBell } from "react-icons/bs";
import { MdOutlineAddLocationAlt } from "react-icons/md";
import { HiOutlineMailOpen } from "react-icons/hi";
import { FaPhoneVolume } from "react-icons/fa6";
import "../Styles/footer.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";

const Footer = () => {
  //Frontend Integration

  const Navigate = useNavigate();
  //Get(Contact Details of Footer)

  const [ContactDetails, setContactDetails] = useState(null);

  const getContactDetails = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/contactdetails/getcontactdetails"
      );
      if (res.status === 201) {
        setContactDetails(res.data.data1);
        // console.log(res.data.data1);
      }
    } catch (error) {
      console.log(error, "data some data");
    }
  };

  // console.log("ContactDetails: ", ContactDetails);

  //Get(Social Media Details of Footer)

  const [SocialMediaDetails, setSocialMediaDetails] = useState(null);

  const getSocialMediaDetails = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/contactsocialmedia/getsocialmediadetails"
      );
      if (res.status === 201) {
        setSocialMediaDetails(res.data.data1);
        // console.log(res.data.data1);
      }
    } catch (error) {
      console.log(error, "data some data");
    }
  };

  // console.log("SocialMediaDetails: ", SocialMediaDetails);

  //Get(Home Services)

  const [Department, setDepartment] = useState([]);
  // console.log(Department, "Department");

  const getHomeDepartment = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/homedepartment/gethomedepartment"
      );
      if (res.status === 201) {
        setDepartment(res.data.status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [AboutContent1, setAboutContent1] = useState([]);
  console.log("AboutContent1", AboutContent1);

  const getAdminContent1 = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/aboutbannercontents/getaboutbannercontents"
      );
      if (res.status === 201) {
        setAboutContent1(res.data.data1);
      }
    } catch (error) {
      console.log(error, "not able to fetch the data");
    }
  };

  const aboutus = AboutContent1.map((item) =>
    item.bannerDescription1.slice(0, 200)
  );
  // console.log("Department", aboutus);

  useEffect(() => {
    getContactDetails();
    getSocialMediaDetails();
    getHomeDepartment();
    getAdminContent1();
  }, []);

  const [email, setEmail] = useState("");
  const sendEmail = async () => {
    if (!email) {
      return alert("Email required !!!");
    }
    try {
      let config = {
        url: "/createfemail",
        method: "post",
        baseURL: "https://lehaim.in/api/testimonials",
        headers: { "content-type": "application/json" },
        data: {
          email: email,
        },
      };
      const response = await axios(config);
      // console.log(response, "response");
      if (response.status == 200) {
        alert("We will revert shortly...");
        setEmail("");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const activeDepartment = Department.filter(
    (item) => item.status === "Inactive"
  );
  // console.log(activeDepartment, "activeDepartment");
  return (
    <div>
      <footer className="footer-btm">
        <div className="container">
          <div className="row">
            <div className="col-md-4 ">
              <div className="row mt-4">
                <img
                  src="/Images/whitelogo.png"
                  alt=""
                  className="foot-logo"
                  style={{ backgroundColor: "black" }}
                />
              </div>
              <div className="row mt-3">
                <p className="text-alignment off">
                  <a href="/about-s">{parse(`<div>${aboutus[0]}...</div>`)}</a>
                </p>
              </div>
              <div className="row mt-2">
                <div className="search-container">
                  <input
                    type="text"
                    className="search-input"
                    placeholder="Your Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <p className="search-icon" onClick={sendEmail}>
                    <BsBell style={{ cursor: "pointer" }} />
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-2"></div>
            <div className="col-md-3 ">
              <div className="row mt-4">
                <h4 className="text-start">Useful Links</h4>
                <a href="/about-s" className="footer-useful-links">
                  {" "}
                  <p className="text-start">About Us</p>
                </a>
                <a href="/service" className="footer-useful-links">
                  {" "}
                  <p className="text-start">Services</p>
                </a>
                <a href="/allblog" className="footer-useful-links">
                  <p className="text-start">Blog</p>
                </a>
                <a href="gallery" className="footer-useful-links">
                  <p className="text-start">Gallery</p>
                </a>
                <a href="/faq" className="footer-useful-links">
                  <p className="text-start">FAQ</p>
                </a>
                <a href="/contact" className="footer-useful-links">
                  <p className="text-start">Contact Us</p>
                </a>
              </div>
            </div>

            <div className="col-md-3 mt-4">
              <div className="row">
                <h4 className="text-start">Our Services</h4>

                {activeDepartment?.map((item) => {
                  return (
                    <p
                      className="text-start"
                      onClick={() => {
                        Navigate("/nephrologistcare", { state: { item } });
                        window.scrollTo(0, 0);
                      }}
                    >
                      {item?.departmentName}
                    </p>
                  );
                })}

                {/* <p className="text-start">Geriatric Care</p>
                <p className="text-start">ICU Care Services</p>
                <p className="text-start">Post Operative Care</p>
                <p className="text-start">Physiotherapy</p>
                <p className="text-start">Doctor Consultation</p> */}
              </div>
            </div>
          </div>

          <div className="container">
            {ContactDetails?.map((item) => {
              return (
                <div className="row foot-middle-part mt-4">
                  <div className="col-md-5">
                    <div
                      className="d-flex gap-3 justify-content-center"
                      style={{ alignItems: "center" }}
                    >
                      <div className="fs-3">
                        <MdOutlineAddLocationAlt />
                      </div>
                      <div>
                        {item?.address} <br />
                        {/* Dasarahalli Main Road Bangalore-560024 */}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-1"></div>
                  <div className="col-md-2">
                    <div className="d-flex gap-3 justify-content-center">
                      <div className="email-icon fs-3">
                        <HiOutlineMailOpen />
                      </div>
                      <div className="email-add">
                        {item?.email} <br />
                        {/* info@lehaim.life */}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-1"></div>

                  <div className="col-md-3">
                    <div className="d-flex gap-3 justify-content-center">
                      <div className="phone1 fs-3">
                        <FaPhoneVolume />
                      </div>
                      <div className="phone2">
                        {item?.phone}
                        <br />
                        {item?.altphone}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div
            className="row last-part-foot mt-4 pb-2"
            style={{ alignItems: "baseline" }}
          >
            <div className="col-md-8 mb-3 mb-md-0">
              <div className="copy-write">
                &copy; 2024{" "}
                <strong style={{ color: "white" }}>
                  LeHaim.Life. All Rights Reserved |
                </strong>
                <span>
                  <a href="/footer_privacypolicy">Privacy Policy </a>{" "}
                </span>
                |{" "}
                <span>
                  <a href="/footer_termsandconditions">Terms & Conditions</a>
                </span>{" "}
                |{" "}
                <span>
                  <a href="/footer_patientscharter">Patient Charter</a>
                </span>
              </div>
            </div>

            {/* social media links  */}

            <div className="col-md-4">
              <div
                className="links"
                style={{
                  display: "flex",
                  justifyContent: "right",
                  gap: " 22px",
                }}
              >
                {SocialMediaDetails?.map((item) => {
                  return (
                    <span className="fs-4 ">
                      <a href={item?.mediaLink} target="_blank">
                        <img
                          src={`https://lehaim.in/SocialMedia/${item?.mediaIcon}`}
                          alt="pic"
                          style={{ width: "25px", height: "25px" }}
                        />
                      </a>
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
