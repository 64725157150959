import React, { useEffect, useState } from "react";
import "../Styles/mainsectiom.css";
import Modal from "react-modal";
import axios from "axios";
import parse from "html-react-parser";

const Mainsection = () => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "90%", // Adjust the width as needed
      maxWidth: "600px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
  };
  const [mainSection, setMainSection] = useState([]);

  const getMainSection = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/homemainsection/getmainsection"
      );
      if (res.status === 201) {
        setMainSection(res.data.sucess);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  useEffect(() => {
    getMainSection();
  }, []);
  return (
    <div>
      {mainSection?.map((item) => {
        return (
          <section className="elementor-section elementor-top-section elementor-element elementor-element-688e22f elementor-section-boxed elementor-section-height-default elementor-section-height-default">
            <div
              className="elementor-container elementor-column-gap-default column-gap-default-in-main-section"
              style={{ margin: "40px" }}
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-8">
                    <div className="cfs">
                      <div className="row">
                        <div className="col-md-8">
                          <div className="elementor-widget-container imh">
                            <div className="theme-title ht-split-text">
                              {/* <h6>{item?.bannerTitle}</h6> */}
                              <h2
                                data-splitting=""
                                className="words chars splitting ht-animated"
                                style={{
                                  "--word-total": 7,
                                  "--char-total": 35,
                                }}
                              >
                                {item?.bannerText}
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="elementor-widget-container phk">
                            {item.bannerDescription == undefined ? (
                              <></>
                            ) : (
                              <>
                                {" "}
                                <p>
                                  {parse(
                                    `<div>${item?.bannerDescription}</div>`
                                  )}
                                </p>{" "}
                              </>
                            )}
                          </div>
                          <div className="elementor-widget-container">
                            <div>
                              <div className="themeht-btn primary-btn">
                                <span>
                                  <a
                                    href="/service"
                                    className="home-about-navigate"
                                  >
                                    Our Services
                                  </a>
                                </span>

                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  x="0px"
                                  y="0px"
                                  width="10"
                                  height="19"
                                  viewBox="0 0 19 19"
                                  xmlSpace="preserve"
                                >
                                  <line
                                    x1="1"
                                    y1="18"
                                    x2="17.8"
                                    y2="1.2"
                                  ></line>
                                  <line x1="1.2" y1="1" x2="18" y2="1"></line>
                                  <line x1="18" y1="17.8" x2="18" y2="1"></line>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="elementor-widget-container">
                        <img
                          decoding="async"
                          width="1200"
                          height="500"
                          src={`https://lehaim.in/MainSection/${item?.image}`}
                          className="attachment-doctean-1200x500 size-doctean-1200x500 wp-image-834 mt-3"
                          alt=""
                          style={{ width: "775px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-5972a68 mb-0 ht-img-animation elementor-widget elementor-widget-image ht-img-active"
                        data-id="5972a68"
                        data-element_type="widget"
                        data-widget_type="image.default"
                      >
                        <div className="elementor-widget-container">
                          <video
                            decoding="async"
                            width="700"
                            height="600"
                            controls
                            muted
                            loop
                            src={`https://lehaim.in/MainSection/${item?.video}`}
                            type="video/*"
                            style={{
                              height: "630px",
                              width: "100%",
                              objectFit: "cover",
                            }}
                            //     {/* src="https://doctean.themeht.com/wp-content/uploads/2024/03/16-700x600.jpg"
                            //     className="attachment-doctean-700x600 size-doctean-700x600 wp-image-835 main-second-img"
                            //     alt=""
                            //       data-aos="zoom-out"
                            // data-aos-duration="2000" */}
                          />
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-9e6a173 elementor-widget elementor-widget-video-popup"
                        data-id="9e6a173"
                        data-element_type="widget"
                        data-widget_type="video-popup.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="video-button" onClick={openModal}>
                            <a className="popup-youtube">
                              <i className="flaticon flaticon-play">
                                <img
                                  src="../Images/play.png"
                                  style={{ width: "30px", marginTop: "-13px" }}
                                  className="play-button-img"
                                />
                              </i>
                            </a>
                            <div className="ht-shape-btn left-side bottom">
                              <svg
                                width="111"
                                height="111"
                                viewBox="0 0 111 111"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0 0C19.33 0 35 15.67 35 35V41C35 60.33 50.67 76 70 76H76C95.33 76 111 91.67 111 111V0H0Z"
                                  fill="none"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                style={customStyles}
                //className="react-model"
              >
                <div className="video-container">
                  {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/M5rqjzWv2X8?si=Ztd1BunEvhygbgiv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                  <video
                    decoding="async"
                    width="700"
                    height="600"
                    controls
                    autoPlay
                    muted
                    loop
                    src={`https://lehaim.in/MainSection/${item?.video}`}
                    type="video/*"
                    style={{ height: "550px", width: "100%" }}
                  />
                </div>
                {/* <button onClick={closeModal}>close</button> */}
              </Modal>
            </div>
          </section>
        );
      })}
    </div>
  );
};

export default Mainsection;
