import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Aos from "aos";
import OwlCarousel from "react-owl-carousel";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";

// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";

import "../Styles/Gallery.css";

import axios from "axios";

const Gallery = () => {
  useEffect(() => {
    Aos.init({ duration: 2500 });
  }, []);

  //Frontend Integration (pushing the data from adminpanel to gallery page)

  //Get(Gallery Banner)

  const [Banner, setBanner] = useState(null);

  const getGalleryBanner = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/gallerybanner/getgallerybanner"
      );
      if (res.status === 201) {
        setBanner(res.data.data1);

        console.log(res.data.data1);
      }
    } catch (error) {
      console.log(error, "data some data");
    }
  };

  //Get(Gallery Images)

  const [Image, setImage] = useState(null);

  const getGalleryImage = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/galleryimage/getgalleryimage"
      );
      if (res.status === 201) {
        setImage(res.data.data1);

        console.log(res.data.data1);
      }
    } catch (error) {
      console.log(error, "data some data");
    }
  };

  //Get(Gallery Video)

  const [Video, setVideo] = useState(null);

  const getGalleryVideo = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/galleryvideo/getgalleryvideo"
      );
      if (res.status === 201) {
        setVideo(res.data.data1);

        console.log(res.data.data1);
      }
    } catch (error) {
      console.log(error, "data some data");
    }
  };

  console.log("Video: ", Video);

  useEffect(() => {
    getGalleryBanner();
    getGalleryImage();
    getGalleryVideo();
  }, []);

  return (
    <>
      {Banner?.map((item) => {
        return (
          <section
            id="about"
            style={{
              backgroundImage: `url(https://lehaim.in/GalleryBanner/${item?.bannerImg})`,
            }}
          >
            <div className="container ">
              <div className="breadcrumbt">
                <div>
                  <h1 className="abtus-heading">{item?.bannerText}</h1>
                </div>

                <div>
                  <Breadcrumb className="mbl-responsive-breadcrumbs">
                    <FontAwesomeIcon
                      icon={faHouse}
                      style={{
                        fontSize: "14px",
                        marginTop: "0px",
                        color: "rgb(25, 107, 156)",
                        padding: "5px",
                      }}
                    />
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Gallery</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>
          </section>
        );
      })}

      {/* <div
                className='head-back-img'
                style={{
                    backgroundImage: "url(../Images/all-department-img.jpg)",
                    width: "100%",
                    height: "150px",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover"
                }}
            >
                <Container>
                    <h1
                        data-aos='fade-right'
                        data-aos-duration='3000'
                        className='text-dark pt-5 fw-light back-img-head'
                    >
                        Gallery
                    </h1>
                </Container>
            </div> */}

      <Container className="mt-5">
        {/* <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            underline="hover"
                            className="d-flex gap-1 breadcrumb-hover"
                            color="inherit"
                            href="/"
                        >
                            <FontAwesomeIcon
                                className="home-icon"
                                icon={faHouse}
                                style={{ fontSize: '14px', marginTop: '3px', color: "rgb(25, 107, 156)" }}
                            />
                             <span style={{color:"rgb(25, 107, 156)"}}>Home</span>
                        </Link>
                        <Typography
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="text.primary"
                        >
                            Gallery
                        </Typography>
                    </Breadcrumbs>
                </div> */}

        {/* <div className="carousel-container mt-4">
                    <OwlCarousel 
                        className='owl-theme' 
                        loop 
                        margin={30} 
                        nav 
                        responsive={{
                            0: { items: 1 },
                            768: { items: 2 },
                            992: { items: 3 },
                            1200: { items: 4 }
                        }}
                    >
                        {Array.from({ length: 8 }).map((_, index) => (
                            <div className="item" key={index}>
                                <Card style={{ width: '100%' }}>
                                    <Card.Img variant="top" />
                                    <img
                                        style={{ width: "100%", height: "180px" }}
                                        src={`../Images/department-img-${index + 1}.jpg`}
                                        alt="Doctor-img"
                                    />
                                    <Card.Body>
                                        <p className='fw-semibold m-0'>Inpatient Room</p>
                                        
                                    </Card.Body>
                                </Card>
                            </div>
                        ))}
                    </OwlCarousel>
                </div> */}

        <Container>
          {/* <h1 className='galleryy mt-5 mb-5'>Gallery</h1> */}
          <div className="row">
            {Image?.map((item) => {
              return (
                <>
                  <div className="col-md-4 mb-4">
                    {/* <img src="../Images/department-img-2.jpg" alt="" className='img' /> */}
                    <img
                      src={`https://lehaim.in/GalleryImages/${item?.galleryImg}`}
                      alt=""
                      className="img"
                    />
                  </div>
                </>
              );
            })}
          </div>

          <div className="row mb-3">
            {Video?.map((item) => {
              return (
                <>
                  <div className="col-md-4">
                    <video controls autoPlay muted loop className="video">
                      <source
                        src={`https://lehaim.in/GalleryVideos/${item?.galleryVideo}`}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </>
              );
            })}
          </div>
        </Container>
      </Container>
    </>
  );
};

export default Gallery;

// import React, { useEffect } from "react";
// import { Container } from 'react-bootstrap';
// import Card from 'react-bootstrap/Card';
// import Button from 'react-bootstrap/Button';
// import Aos from "aos";
// import Carousel from 'react-grid-carousel';
// import { Breadcrumbs, Link, Typography } from "@mui/material";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faHouse } from "@fortawesome/free-solid-svg-icons";

// import "../Styles/Gallery.css";

// const Gallery = () => {
//     useEffect(() => {
//         Aos.init({ duration: 2500 });
//     }, []);

//     return (
//         <>
//             <div
//                 className='head-back-img'
//                 style={{
//                     backgroundImage: "url(../Images/all-department-img.jpg)",
//                     width: "100%",
//                     height: "150px",
//                     backgroundRepeat: "no-repeat",
//                     backgroundPosition: "center",
//                     backgroundSize: "cover"
//                 }}
//             >
//                 <Container>
//                     <h1
//                         data-aos='fade-right'
//                         data-aos-duration='3000'
//                         className='text-dark pt-5 fw-light back-img-head'
//                     >
//                         Gallery
//                     </h1>
//                 </Container>
//             </div>

//             <Container className="mt-5">
//                 <div>
//                     <Breadcrumbs aria-label="breadcrumb">
//                         <Link
//                             underline="hover"
//                             className="d-flex gap-1 breadcrumb-hover"
//                             color="inherit"
//                             href="/"
//                         >
//                             <FontAwesomeIcon
//                                 icon={faHouse}
//                                 style={{ fontSize: '14px', marginTop: '3px' }}
//                             />
//                             Home
//                         </Link>
//                         <Typography
//                             sx={{ display: 'flex', alignItems: 'center' }}
//                             color="text.primary"
//                         >
//                             Gallery
//                         </Typography>
//                     </Breadcrumbs>
//                 </div>

//                 <div className="carousel-container mt-4">
//                     <Carousel
//                         cols={4}
//                         rows={2}
//                         gap={10}
//                         loop
//                         responsiveLayout={[
//                             { breakpoint: 1200, cols: 3, rows: 2, gap: 10 },
//                             { breakpoint: 992, cols: 2, rows: 2, gap: 10 },
//                             { breakpoint: 768, cols: 1, rows: 2, gap: 10 }
//                         ]}
//                     >
//                         {Array.from({ length: 8 }).map((_, index) => (
//                             <Carousel.Item key={index} className="carousel-item" data-aos='fade-up' data-aos-duration={`${1500 + index * 500}`}>
//                                 <Card style={{ width: '100%' }}>
//                                     <Card.Img variant="top" />
//                                     <img
//                                         style={{ width: "100%", height: "180px" }}
//                                         src={`../Images/department-img-${index + 1}.jpg`}
//                                         alt="Doctor-img"
//                                     />
//                                     <Card.Body>
//                                         <p className='fw-semibold m-0'>Inpatient Room</p>
//                                         <p className='fw-light' style={{ fontStyle: "italic" }}>
//                                             Facility
//                                         </p>
//                                     </Card.Body>
//                                 </Card>
//                             </Carousel.Item>
//                         ))}
//                     </Carousel>

//                     <a href="/oldpost">
//                         <Button className="red-btn-5 m-5">Old Post</Button>
//                     </a>

//                 </div>
//             </Container>
//         </>
//     );
// };

// export default Gallery;

// import React, { useEffect } from "react";
// import { Container } from 'react-bootstrap';
// import Card from 'react-bootstrap/Card';
// import Button from 'react-bootstrap/Button';
// import Aos from "aos";
// import Carousel from 'react-grid-carousel';
// import { Breadcrumbs, Link, Typography } from "@mui/material";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faHouse } from "@fortawesome/free-solid-svg-icons";

// import "../Styles/Gallery.css";

// const Gallery = () => {
//     useEffect(() => {
//         Aos.init({ duration: 2500 });
//     }, []);

//     return (
//         <>
//             <div
//                 className='head-back-img'
//                 style={{
//                     backgroundImage: "url(../Images/all-department-img.jpg)",
//                     width: "100%",
//                     height: "150px",
//                     backgroundRepeat: "no-repeat",
//                     backgroundPosition: "center",
//                     backgroundSize: "cover"
//                 }}
//             >
//                 <Container>
//                     <h1
//                         data-aos='fade-right'
//                         data-aos-duration='3000'
//                         className='text-dark pt-5 fw-light back-img-head'
//                     >
//                         Gallery
//                     </h1>
//                 </Container>
//             </div>

//             <Container className="mt-5">
//                 <div>
//                     <Breadcrumbs aria-label="breadcrumb">
//                         <Link
//                             underline="hover"
//                             className="d-flex gap-1 breadcrumb-hover"
//                             color="inherit"
//                             href="/"
//                         >
//                             <FontAwesomeIcon
//                                 icon={faHouse}
//                                 style={{ fontSize: '14px', marginTop: '3px' }}
//                             />
//                             Home
//                         </Link>
//                         <Typography
//                             sx={{ display: 'flex', alignItems: 'center' }}
//                             color="text.primary"
//                         >
//                             Gallery
//                         </Typography>
//                     </Breadcrumbs>
//                 </div>

//                 <div className="carousel-container mt-4">

//                     <Carousel cols={4} rows={2} gap={10} loop>
//                         {Array.from({ length: 8 }).map((_, index) => (
//                             <Carousel.Item key={index} className="carousel-item" data-aos='fade-up' data-aos-duration={`${1500 + index * 500}`}>
//                                 <Card className="col-3" style={{ width: '14rem' }}>
//                                     <Card.Img variant="top" />
//                                     <img
//                                         style={{ width: "100%", height: "180px" }}
//                                         src={`../Images/department-img-${index + 1}.jpg`}
//                                         alt="Doctor-img"
//                                     />

//                                     <Card.Body>
//                                         <p className='fw-semibold m-0'>Inpatient Room</p>
//                                         <p className='fw-light' style={{ fontStyle: "italic" }}>
//                                             Facility
//                                         </p>
//                                     </Card.Body>
//                                 </Card>
//                             </Carousel.Item>
//                         ))}
//                     </Carousel>

//                      <a href="/oldpost">
//                         <Button className="red-btn-5 m-5">Old Post</Button>
//                     </a>

//                 </div>
//             </Container>
//         </>
//     );
// };

// export default Gallery;

// import React, { useEffect, useState } from "react";
// // import Breadcrumb from 'react-bootstrap/Breadcrumb';
// import { Container } from 'react-bootstrap';
// import Card from 'react-bootstrap/Card';
// import Button from 'react-bootstrap/Button';
// import Aos from "aos";
// import Carousel from 'react-grid-carousel'
// import { Breadcrumbs, Link, Typography } from "@mui/material";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faHouse } from "@fortawesome/free-solid-svg-icons";

// import "../Styles/Gallery.css"

// const Gallery = () => {

//     useEffect(() => {
//         Aos.init({ duration: 2500 })
//     }, [])

//     return (
//         <>

//             <div className='head-back-img' style={{ backgroundImage: "url(../Images/all-department-img.jpg)", width: "100%", height: "150px", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }}>

//                 <Container>
//                     <h1 data-aos='fade-right' data-aos-duration='3000' className='text-dark pt-5 fw-light back-img-head'>Gallery</h1>
//                 </Container>
//             </div>

//             <Container className="mt-5">
//                 <div>
//                     <Breadcrumbs
//                         aria-label="breadcrumb">
//                         <Link
//                             underline="hover"
//                             className="d-flex gap-1 breadcrumb-hover"
//                             color="inherit"
//                             href="/"
//                         >
//                             <FontAwesomeIcon icon={faHouse}
//                                 style={{ fontSize: '14px', marginTop: '3px' }} />
//                             Home
//                         </Link>
//                         <Typography
//                             sx={{ display: 'flex', alignItems: 'center' }}
//                             color="text.primary"
//                         >
//                             {/* <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" /> */}
//                             Gallery
//                         </Typography>
//                     </Breadcrumbs>
//                 </div>

//                 <div className="container mt-4">

//                     <Carousel cols={4} rows={2} gap={10} loop>
//                         <Carousel.Item data-aos='fade-up' data-aos-duration='1500'>
//                             <Card data-aos='fade-up' data-aos-duration='1500' className="col-3" style={{ width: '14rem', }}>
//                                 <Card.Img variant="top" />
//                                 <img style={{ width: "100%", height: "180px" }} src="../Images/department-img-1.jpg" alt="Doctor-img" />
//                                 <Card.Body>
//                                     <p className='fw-semibold m-0'>Inpatient Room</p>
//                                     <p className='fw-light' style={{ fontStyle: "italic" }} >Facility</p>
//                                 </Card.Body>
//                             </Card>
//                         </Carousel.Item>

//                         <Carousel.Item>
//                             <Card data-aos='fade-up' data-aos-duration='2000' className="col-3" style={{ width: '14rem', }}>
//                                 <Card.Img variant="top" />
//                                 <img style={{ width: "100%", height: "180px" }} src="../Images/department-img-2.jpg" alt="Doctor-img" />
//                                 <Card.Body>
//                                     <p className='fw-semibold m-0'>Inpatient Room</p>
//                                     <p className='fw-light' style={{ fontStyle: "italic" }} >Facility</p>
//                                 </Card.Body>
//                             </Card>
//                         </Carousel.Item>

//                         <Carousel.Item>
//                             <Card data-aos='fade-up' data-aos-duration='2500' className="col-3" style={{ width: '14rem', }}>
//                                 <Card.Img variant="top" />
//                                 <img style={{ width: "100%", height: "180px" }} src="../Images/department-img-3.jpg" alt="Doctor-img" />
//                                 <Card.Body>
//                                     <p className='fw-semibold m-0'>Inpatient Room</p>
//                                     <p className='fw-light' style={{ fontStyle: "italic" }} >Facility</p>
//                                 </Card.Body>
//                             </Card>
//                         </Carousel.Item>

//                         <Carousel.Item>
//                             <Card data-aos='fade-up' data-aos-duration='3000' className="col-3" style={{ width: '14rem', }}>
//                                 <Card.Img variant="top" />
//                                 <img style={{ width: "100%", height: "180px" }} src="../Images/department-img-4.jpg" alt="Doctor-img" />
//                                 <Card.Body>
//                                     <p className='fw-semibold m-0'>Inpatient Room</p>
//                                     <p className='fw-light' style={{ fontStyle: "italic" }} >Facility</p>
//                                 </Card.Body>
//                             </Card>
//                         </Carousel.Item>

//                         <Carousel.Item data-aos='fade-up' data-aos-duration='1500'>
//                             <Card data-aos='fade-up' data-aos-duration='1500' className="col-3" style={{ width: '14rem', }}>
//                                 <Card.Img variant="top" />
//                                 <img style={{ width: "100%", height: "180px" }} src="../Images/department-img-5.jpg" alt="Doctor-img" />
//                                 <Card.Body>
//                                     <p className='fw-semibold m-0'>Inpatient Room</p>
//                                     <p className='fw-light' style={{ fontStyle: "italic" }} >Facility</p>
//                                 </Card.Body>
//                             </Card>
//                         </Carousel.Item>

//                         <Carousel.Item>
//                             <Card data-aos='fade-up' data-aos-duration='2000' className="col-3" style={{ width: '14rem', }}>
//                                 <Card.Img variant="top" />
//                                 <img style={{ width: "100%", height: "180px" }} src="../Images/department-img-6.jpg" alt="Doctor-img" />
//                                 <Card.Body>
//                                     <p className='fw-semibold m-0'>Inpatient Room</p>
//                                     <p className='fw-light' style={{ fontStyle: "italic" }} >Facility</p>
//                                 </Card.Body>
//                             </Card>
//                         </Carousel.Item>

//                         <Carousel.Item>
//                             <Card data-aos='fade-up' data-aos-duration='2500' className="col-3" style={{ width: '14rem', }}>
//                                 <Card.Img variant="top" />
//                                 <img style={{ width: "100%", height: "180px" }} src="../Images/department-img-7.jpg" alt="Doctor-img" />
//                                 <Card.Body>
//                                     <p className='fw-semibold m-0'>Inpatient Room</p>
//                                     <p className='fw-light' style={{ fontStyle: "italic" }} >Facility</p>
//                                 </Card.Body>
//                             </Card>
//                         </Carousel.Item>

//                         <Carousel.Item>
//                             <Card data-aos='fade-up' data-aos-duration='3000' className="col-3" style={{ width: '14rem', }}>
//                                 <Card.Img variant="top" />
//                                 <img style={{ width: "100%", height: "180px" }} src="../Images/department-img-8.jpg" alt="Doctor-img" />
//                                 <Card.Body>
//                                     <p className='fw-semibold m-0'>Inpatient Room</p>
//                                     <p className='fw-light' style={{ fontStyle: "italic" }} >Facility</p>
//                                 </Card.Body>
//                             </Card>
//                         </Carousel.Item>

//                     </Carousel>
//                     <a href="/oldpost"> <Button className="red-btn-5 m-5">Old Post</Button> </a>

//                 </div>

//             </Container>
//         </>
//     );
// };

// export default Gallery;
