import React, { useEffect, useState } from "react";
import "../Styles/latestblogs.css";
import { SlCalender } from "react-icons/sl";
import { IoBookmarksOutline } from "react-icons/io5";
import OwlCarousel from "react-owl-carousel";
import axios from "axios";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";

const LatestBlogs = () => {
  const navigate = useNavigate();
  const options = {
    margin: 20,
    responsiveClass: true,
    nav: true,
    dots: true,
    autoplay: true,
    loop: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 3,
      },
    },
  };

  const [LatestBlogs, setLatestBlogs] = useState([]);
  console.log("Department=============>", LatestBlogs);
  const getLatestBlog = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/homelatestblog/getlatestblog"
      );
      if (res.status === 201) {
        setLatestBlogs(res.data.status);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  useEffect(() => {
    getLatestBlog();
  }, []);

  //Active logic(admin panel)

  const isActive = LatestBlogs.some((item) => item.status === "Inactive");
  // console.log("Department=============>", isActive);

  return (
    <div>
      {isActive ? (
        <>
          <div className="latest-blog-theme1">
            <div className="blog-theme1">Latest Blogs & Articles</div>
          </div>

          <div className="container" style={{ paddingBottom: "40px" }}>
            <OwlCarousel
              className="owl-theme"
              dots={true}
              loop={false}
              autoPlay={true}
              margin={8}
              {...options}
            >
              {LatestBlogs?.map((item) => {
                if (item.status === "Inactive") {
                  return (
                    <div
                      key={item.id}
                      className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-b857cf6"
                      data-id="b857cf6"
                      onClick={() => {
                        navigate("/blog", { state: { item } });
                      }}
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-2fdbb4f elementor-widget elementor-widget-feature"
                          data-id="2fdbb4f"
                          data-element_type="widget"
                          data-widget_type="feature.default"
                        >
                          <div className="elementor-widget-container">
                            <div
                              className="featured-item style-1 our-latest-blog-cor"
                              style={{
                                padding: "0px",
                                width: "359px",
                                height: "450px",
                              }}
                            >
                              <img
                                src={`https://lehaim.in/Blog/${item?.blogImage}`}
                                style={{
                                  width: "359px",
                                  borderRadius: "20px",
                                  height: "260px",
                                }}
                                className="our-latest-blog-img"
                              />
                              <div
                                className="ht-shape-btn"
                                style={{
                                  left: "0",
                                  right: "auto",
                                  transform: " rotate(-90deg)",
                                }}
                              >
                                <svg
                                  width="111"
                                  height="111"
                                  viewBox="0 0 111 111"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0 0C19.33 0 35 15.67 35 35V41C35 60.33 50.67 76 70 76H76C95.33 76 111 91.67 111 111V0H0Z"
                                    fill="none"
                                  ></path>
                                </svg>
                              </div>

                              <div
                                className="featured-service-icon"
                                style={{ left: "0px" }}
                              >
                                {/* <span style={{color:"#196b9c"}}>03</span> */}
                                <img
                                  src={`https://lehaim.in/Blog/${item?.blogIcon}`}
                                  alt=""
                                  style={{ width: "80px", height: "50px" }}
                                />
                              </div>
                              {/* <div className="featured-icon">
                          <i className="flaticon flaticon-stethoscope-3">
                            <img src="../Images/Family-Centered Icon.png" width={80} className="c-img"/>
                          </i>
                        </div> */}
                              <div
                                className="ht-shape-btn latest-blog-svg"
                                style={{
                                  transform: "rotate(90deg)",
                                  bottom: "-342px",
                                  right: "unset",
                                  left: "-25.5%",
                                }}
                              >
                                <svg
                                  width="111"
                                  height="111"
                                  viewBox="0 0 111 111"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0 0C19.33 0 35 15.67 35 35V41C35 60.33 50.67 76 70 76H76C95.33 76 111 91.67 111 111V0H0Z"
                                    fill="none"
                                  ></path>
                                </svg>
                              </div>
                              <div
                                className="featured-service-icon f-m-s-i"
                                style={{ right: "0px", top: "383px" }}
                              >
                                {/* <span style={{color:"#196b9c"}}>03</span> */}
                                <img
                                  src="../Images/up-right-arrow.png"
                                  alt=""
                                  style={{ width: "20px" }}
                                />
                              </div>
                              <div
                                className="featured-desc "
                                style={{ paddingRight: "10px" }}
                              >
                                <div
                                  className="d-flex mt-3 latest-blog-mobile-res"
                                  style={{ justifyContent: "space-between" }}
                                >
                                  <div className="d-flex gap-1 latest-blog-mobile-res-section">
                                    <div
                                      className="react-icon"
                                      style={{ color: "#65b145" }}
                                    >
                                      <SlCalender />
                                    </div>
                                    <div className="date-text">
                                      {`${new Date(item?.blogDate).getDate()}-${
                                        new Date(item?.blogDate).getMonth() + 1
                                      }-${new Date(
                                        item?.blogDate
                                      ).getFullYear()}`}
                                    </div>
                                  </div>
                                  {/* =============== */}
                                  <div className="d-flex latest-blog-mobile-res-section">
                                    <div
                                      className="react-icon"
                                      style={{ color: "#65b145" }}
                                    >
                                      <IoBookmarksOutline />
                                    </div>
                                    <div className="date-text">
                                      {item?.blogBookmark}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="service-title d-flex flex-wrap mt-3"
                                  style={{
                                    color: "#196b9c",
                                    width: "100%",
                                    textAlign: "justify",
                                    lineHeight: "1.2",
                                  }}
                                >
                                  {parse(
                                    `<div style="font-size:24px">${item?.blogTitle?.slice(
                                      0,
                                      90
                                    )}....</div>`
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </OwlCarousel>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default LatestBlogs;
