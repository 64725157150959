import React, { useEffect, useState } from "react";
import Aos from "aos";
import { AiFillFolder } from "react-icons/ai";
import { MdOutlineMailOutline } from "react-icons/md";
import {
  BiSolidPhone,
  BiLogoFacebook,
  BiLogoTwitter,
  BiLogoLinkedin,
} from "react-icons/bi";
import { TfiYoutube } from "react-icons/tfi";
import { TiSocialGooglePlus } from "react-icons/ti";
import { FaPinterest } from "react-icons/fa";
import { ImInstagram } from "react-icons/im";
import { Breadcrumb, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { faHouse } from "@fortawesome/free-solid-svg-icons";

import "../Styles/Contact.css";
import axios from "axios";

export const Contact = () => {
  useEffect(() => {
    Aos.init();
    // window.scroll(0, 0)
  });

  //Frontend Integration

  //Get(Contact Banner)

  const [Banner, setBanner] = useState(null);

  const getContactBanner = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/contactbanner/getcontactbanner"
      );
      if (res.status === 201) {
        setBanner(res.data.data1);

        console.log(res.data.data1);
      }
    } catch (error) {
      console.log(error, "data some data");
    }
  };

  //Post(Contact Enquiry Form)

  const [Desc, setDesc] = useState();

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setDesc(data);
  };
  const [Name, setName] = useState("");
  const [Phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [Service, setService] = useState("");
  const [Message, setMessage] = useState("");

  const validatePhoneNumber = (number) => {
    const re = /^[6-9]\d{9}$/;
    return re.test(number);
  };
  // Post
  const postContactEnquiryForm = async () => {
    if (!Name) {
      return alert("Name required!");
    }
    if (!Email) {
      return alert("Email required!");
    }
    if (!Phone) {
      return alert("Phone required!");
    }
    if (!validatePhoneNumber(Phone)) {
      return alert("Please enter a valid phone number (10 digits)");
    }
    if (!Service) {
      return alert("Service required!");
    }
    if (!Message) {
      return alert("Message required!");
    }
    try {
      const config = {
        url: "/createcontactenquiryform",
        method: "post",
        baseURL: "https://lehaim.in/api/contactenquiryform",
        headers: { "content-type": "application/json" },
        data: {
          name: Name,
          phone: Phone,
          email: Email,
          message: Message,
          service: Service,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("We will call you shortly...");
        setName("");
        setPhone("");
        setEmail("");
        setService("");
        setMessage("");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  //Get(Home Services)

  const [Department, setDepartment] = useState([]);

  const getHomeDepartment = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/homedepartment/gethomedepartment"
      );
      if (res.status === 201) {
        setDepartment(res.data.status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log("Department", Department);

  useEffect(() => {
    getContactBanner();
    getHomeDepartment();
  }, []);

  return (
    <div>
      {Banner?.map((item) => {
        return (
          <section
            id="about"
            style={{
              backgroundImage: `url(https://lehaim.in/ContactBanner/${item?.bannerImg})`,
            }}
          >
            <div className="container ">
              <div className="breadcrumbt">
                <div>
                  <h1 className="abtus-heading">{item?.bannerText}</h1>
                </div>

                <div className="mbl-responsive-breadcrumbs">
                  <Breadcrumb>
                    <FontAwesomeIcon
                      icon={faHouse}
                      style={{
                        fontSize: "14px",
                        marginTop: "0px",
                        color: "rgb(25, 107, 156)",
                        padding: "5px",
                      }}
                    />
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>
          </section>
        );
      })}

      {/* <div className='head-back-img' style={{ backgroundImage: "url(../Images/all-department-img.jpg)", width: "100%", height: "150px", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", }}>

                <Container >
                    <h1 data-aos='fade-right'data-aos-duration='3000' className='text-dark fw-light pt-5 back-img-head'>Contact</h1>
                </Container>
            </div> */}

      <Container className="mt-5">
        {/* <div className="mb-4">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            underline="hover"
                            className="d-flex gap-1 breadcrumb-hover"
                            color="inherit"
                            href="/home"
                        >
                         <FontAwesomeIcon icon={faHouse}
                          style={{fontSize:'14px', marginTop:'3px', color: "rgb(25, 107, 156)"}} />
                         <span style={{color:"rgb(25, 107, 156)"}}>Home</span>
                        </Link>
                        <Typography
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="text.primary"
                        >
                            Contact
                        </Typography>
                    </Breadcrumbs>
                </div> */}

        <Container>
          <div className="row">
            <div
              className="col-md-6 mb-5 "
              data-aos="zoom-in"
              data-aos-duration="3000"
            >
              <iframe
                className="map-img"
                src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d3886.6868145855365!2d77.6039842!3d13.0555962!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDAzJzIwLjAiTiA3N8KwMzYnMjQuOSJF!5e0!3m2!1sen!2sin!4v1722600897652!5m2!1sen!2sin"
                width={450}
                height={450}
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>

            <div
              className="col-md-6 mb-5"
              data-aos="zoom-in"
              data-aos-duration="3000"
            >
              <div className="form-containers">
                <h3 style={{ color: "#196B9C" }} className="mt-2">
                  Request a Callback
                </h3>
                <div className="form-sections">
                  <div className="form-groups">
                    <input
                      onChange={(e) => setName(e.target.value)}
                      value={Name}
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Your Name"
                      className="selections"
                    />
                  </div>

                  <div className="form-groups">
                    <input
                      value={Email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email Address"
                      className="selections"
                    />
                  </div>

                  <div className="form-groups">
                    <input
                      type="tel"
                      value={Phone}
                      onChange={(e) => setPhone(e.target.value)}
                      id="phone"
                      name="phone"
                      placeholder="Phone Number"
                      className="selections"
                    />
                  </div>

                  <div className="form-groups">
                    <select
                      value={Service}
                      onChange={(e) => setService(e.target.value)}
                      id="option"
                      name="option"
                      className="selections"
                    >
                      <option value="option1">Select Service</option>

                      {Department?.map((item) => {
                        return (
                          <option value="megha">{item?.departmentName}</option>
                        );
                      })}
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>

                <div className="form-groups mt-2 mb-2">
                  <textarea
                    value={Message}
                    onChange={(e) => setMessage(e.target.value)}
                    id="message"
                    name="message"
                    rows="4"
                    cols="50"
                    placeholder="Your Message"
                    className="selections"
                  ></textarea>
                </div>
                <div
                  onClick={postContactEnquiryForm}
                  style={{ backgroundColor: "#65b145" }}
                  className="btn-callbacks vv"
                >
                  Submit
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default Contact;
