import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";

const AdminContactbanner = () => {
  // Ck Editor Code
  const [Desc, setDesc] = useState();

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setDesc(data);
  };

  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delet modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  //Frontend Integration
  const [BannerImage, setBannerImage] = useState("");
  const [BannerText, setBannerText] = useState("");

  //Post

  const postContactBanner = async () => {
    if (!BannerImage) {
      return alert("Please Select Image...");
    }
    // if(!BannerText){
    //   return alert ("Please Enter Banner Text...")
    // }

    try {
      const config = {
        url: "/createcontactbanner",
        method: "post",
        baseURL: "https://lehaim.in/api/contactbanner",
        headers: { "content-type": "multipart/form-data" },
        data: {
          bannerImg: BannerImage,
          bannerText: BannerText,
        },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.status);
        handleClose3();
        getContactBanner();

        setBannerImage("");
        setBannerText("");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  //Get

  const [Banner, setBanner] = useState(null);

  const getContactBanner = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/contactbanner/getcontactbanner"
      );
      if (res.status === 201) {
        setBanner(res.data.data1);

        console.log(res.data.data1);
      }
    } catch (error) {
      console.log(error, "data some data");
    }
  };

  console.log("Banner: ", Banner);

  //Edit

  const [ContactBannerId, setContactBannerId] = useState({});
  const editContactBanner = async () => {
    try {
      const config = {
        url: "/updatecontactbanner/" + ContactBannerId?._id,
        method: "put",
        baseURL: "https://lehaim.in/api/contactbanner",
        headers: { "content-type": "multipart/form-data" },
        data: {
          bannerImg: BannerImage,
          bannerText: BannerText,
        },
      };

      let res = await axios(config);
      if (res.status === 201) {
        alert(res.data.success);
        handleClose4();
        getContactBanner();

        setBannerImage("");

        setBannerText("");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  //Delete

  const deleteContactBanner = async () => {
    try {
      let res = await axios.delete(
        `https://lehaim.in/api/contactbanner/deletecontactbanner/${ContactBannerId?._id}`
      );
      if (res.status === 201) {
        alert(res.data.message);
        getContactBanner();
        handleClose5();
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  useEffect(() => {
    getContactBanner();
  }, []);

  return (
    <div>
      {/* <div className="col-lg-4 d-flex justify-content-center">
      <div class="input-group ">
        <span class="input-group-text" id="basic-addon1">
          <BsSearch />
        </span>
        <input
          type="text"
          class="form-control"
          placeholder="Search..."
          aria-describedby="basic-addon1"
        />
      </div>
    </div> */}

      <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c "> Contact Slider</h2>
          <div className="d-flex gap-3">
            {/* to hide the add button */}

            {Banner?.length ? (
              ""
            ) : (
              <button className="admin-add-btn" onClick={handleShow3}>
                Add Contact Banner
              </button>
            )}
          </div>
        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>Banner Image</th>

                <th>Text</th>

                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {Banner?.map((item, i) => {
                return (
                  <tr>
                    <td>
                      <img
                        src={`https://lehaim.in/ContactBanner/${item?.bannerImg}`}
                        alt="pic"
                        style={{ width: "75px", height: "75px" }}
                      />
                    </td>

                    <td style={{ paddingTop: "10px" }}>{item?.bannerText}</td>

                    <td>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <BiSolidEdit
                            className=""
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "rgb(101, 177, 69)",
                            }}
                            onClick={() => {
                              handleShow4();
                              setContactBannerId(item);
                            }}
                          />{" "}
                        </div>
                        <div>
                          <AiFillDelete
                            className=""
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "rgb(25, 107, 156)",
                            }}
                            onClick={() => {
                              handleShow5();
                              setContactBannerId(item);
                              // setDatas(item?._id);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* Add Package modal for Slider */}

        <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
          <Modal.Header closeButton>
            <Modal.Title>Add Contact Banner</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Banner Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setBannerImage(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Banner Text</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  onChange={(e) => setBannerText(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={postContactBanner}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal for Slider */}

        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              Edit Contact Slider
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Contact Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setBannerImage(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Contact Text</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  onChange={(e) => setBannerText(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={editContactBanner}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Delet Package modal for Slider */}

        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={deleteContactBanner}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AdminContactbanner;
