import React, { useEffect, useState } from "react";
import "../Styles/footer.css";

import axios from "axios";
import parse from "html-react-parser";

const FooterTermsAndConditions = () => {
  //Get

  const [TermsAndConditions, setTermsAndConditions] = useState([]);
  const getTermAndConditions = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/termsandconditions/getTermsAndConditions"
      );
      if (res.status === 201) {
        setTermsAndConditions(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getTermAndConditions();
  }, []);

  return (
    <div className="container privacy-police">
      <p className="privacy">TERMS AND CONDITIONS </p>
      {TermsAndConditions?.map((item) => {
        return (
          <div className="row ">
            <div className="col-md-12">
              {parse(`<div>${item?.description}</div>`)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FooterTermsAndConditions;
