import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image, Form } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import parse from "html-react-parser";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import axios from "axios";

const AdminTermsAndConditions = () => {
  // Ck Editor Code
  const [Desc, setDesc] = useState();

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setDesc(data);
  };

  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delete modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  //Post
  const AdminTermsAndConditions = async () => {
    console.log("ksahdfasdkhsadhaskdjh");

    try {
      const config = {
        url: "/createTermsAndConditions",
        method: "post",
        baseURL: "https://lehaim.in/api/termsandconditions",
        headers: { "content-type": "application/json" },
        data: {
          description: Desc,
        },
      };
      console.log("config", "jsjs", config);

      let res = await axios(config);
      if (res.status === 200) {
        alert(res?.data?.status);
        handleClose3();
        getTermAndConditions();
        // setDesc("");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  //Get

  const [TermsAndConditions, setTermsAndConditions] = useState([]);
  const getTermAndConditions = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/termsandconditions/getTermsAndConditions"
      );
      if (res.status === 201) {
        setTermsAndConditions(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  //Update
  const [TermsAndConditionsId, setTermsAndConditionsId] = useState({});
  const EditTermsAndConditions = async () => {
    try {
      const config = {
        url: "/updateTermsAndConditions/" + TermsAndConditionsId?._id,
        method: "put",
        baseURL: "https://lehaim.in/api/termsandconditions",
        headers: { "content-type": "application/json" },
        data: {
          description: Desc,
        },
      };

      console.log("config", "kadhaksjdha", config);

      let res = await axios(config);
      if (res.status === 201) {
        alert(res.data.status);
        handleClose4();
        getTermAndConditions();
        setDesc("");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  //Delete
  const DeleteTermsAndConditions = async () => {
    console.log(
      "TermsAndConditionsId?._id",
      "sksjdhkajs",
      TermsAndConditionsId?._id
    );

    try {
      let res = await axios.delete(
        `https://lehaim.in/api/termsandconditions/deleteTermsAndConditions/${TermsAndConditionsId?._id}`
      );
      if (res.status === 200) {
        alert(res.data.message);
        console.log(res.data);
        getTermAndConditions("");
        handleClose5();
      }
    } catch (error) {
      alert(error.message);
    }
  };

  useEffect(() => {
    getTermAndConditions();
  }, []);

  return (
    <div>
      <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Terms and Conditions</h2>
          <div className="d-flex gap-3">
            {TermsAndConditions?.length ? (
              ""
            ) : (
              <button className="admin-add-btn" onClick={handleShow3}>
                Add Terms and Conditions
              </button>
            )}
          </div>
        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                {/* <th>Sl.No</th> */}

                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {TermsAndConditions.map((item, i) => {
                return (
                  <tr>
                    {/* <td>{i + 1}</td> */}

                    <td style={{ paddingTop: "10px" }}>
                      {parse(`<div>${item?.description}</div>`)}
                    </td>

                    <td>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <BiSolidEdit
                            className=""
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "rgb(101, 177, 69)",
                            }}
                            onClick={() => {
                              handleShow4();
                              setTermsAndConditionsId(item);
                            }}
                          />{" "}
                        </div>
                        <div>
                          <AiFillDelete
                            className=""
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "rgb(25, 107, 156)",
                            }}
                            onClick={() => {
                              setTermsAndConditionsId(item);
                              handleShow5();
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* Add Package modal for Slider */}
        <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
          <Modal.Header closeButton>
            <Modal.Title>Add Terms and Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div className="row">
                <div className="do-sear mt-2">
                  <label>Add Blog Name</label>
                  <Form.Select
                 onChange={(e)=>setBlogCategory(e.target.value)}
                >
                  <option>select</option>
                  {LatestBlogs?.map((item, i) => {
                    return (
                      <option value={item?._id}>
                        {parse(`<div>${item?.blogTitle}</div>`)}
                      </option>
                    );
                  })}
                </Form.Select>
                  
                </div>
              </div> */}

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Terms and Conditions Description</label>

                <CKEditor editor={ClassicEditor} onChange={handleChange} />
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={AdminTermsAndConditions}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal for Slider */}
        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              Edit Terms and Conditions
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div className="row">
              <div className="do-sear mt-2">
                  <label>Edit Blog Name</label>
                  <Form.Select
                 onChange={(e)=>setBlogCategory(e.target.value)}
                >
                  <option>select</option>
                  {LatestBlogs?.map((item, i) => {
                    return (
                      <option value={item?._id}>
                        {parse(`<div>${item?.blogTitle}</div>`)}
                      </option>
                    );
                  })}
                </Form.Select>
                  
                </div>
              </div> */}

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Terms and Conditions Description</label>

                <CKEditor editor={ClassicEditor} onChange={handleChange} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={EditTermsAndConditions}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Delet Package modal for Slider */}
        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data??
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={DeleteTermsAndConditions}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AdminTermsAndConditions;
