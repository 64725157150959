import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import parse from "html-react-parser";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const DocTestimonial = () => {
  // Ck Editor Code
  const [Desc, setDesc] = useState(" ");
  const [name, setName] = useState(" ");
  const [specification, setSpecification] = useState(" ");
  const [serviceimg, setServiceimg] = useState("");
  const handleChange = (e, editor) => {
    const data = editor.getData();
    setDesc(data);
  };

  const handleChange1 = (e, editor) => {
    const data = editor.getData();
    setDesc1(data);
  };

  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delete modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const LatestBlog = async () => {
    try {
      const config = {
        url: "/createtestimonial",
        method: "post",
        baseURL: "https://lehaim.in/api/testimonials",
        headers: { "content-type": "multipart/form-data" },
        data: {
          description: Desc,
          name: name,
          specification: specification,
          serviceimg: serviceimg,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.message);
        handleClose3();
        setName("");
        setSpecification("");
        fetchTestimonials("");
      }
    } catch (error) {
      alert(error.response?.data?.error || "An error occurred");
    }
  };

  const [data, setData] = useState([]);
  console.log(data, "data");

  const fetchTestimonials = async () => {
    try {
      const response = await axios.get(
        "https://lehaim.in/api/testimonials/gettestimonials"
      );
      console.log(response.data.data);
      setData(response.data.data);
    } catch (error) {
      console.error(
        "Error fetching testimonials",
        error.response?.data?.error || "An error occurred"
      );
    }
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const [testimonialsId, settestimonialsId] = useState({});
  // console.log(testimonialsId, "testimonialsId");
  const [Desc1, setDesc1] = useState(" ");
  const [name1, setName1] = useState(" ");
  const [specification1, setSpecification1] = useState(" ");
  const [serviceimg1, setServiceimg1] = useState("");
  const UpdateLatestBlog = async () => {
    console.log(Desc1);
    console.log(name1);
    console.log(specification1);

    try {
      const config = {
        url: "/updatetestimonials/" + testimonialsId?._id,
        method: "put",
        baseURL: "https://lehaim.in/api/testimonials",
        headers: { "content-type": "multipart/form-data" },
        data: {
          description: Desc1,
          name: name1,
          specification: specification1,
          serviceimg: serviceimg1,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Data updated successfully");
        handleClose4();
        fetchTestimonials();
        setDesc1(" ");
        setName1(" ");
        setSpecification1(" ");
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log("sqswwd", LatestBlogId);

  const DeleteLatestBlog = async () => {
    try {
      let res = await axios.delete(
        `https://lehaim.in/api/testimonials/deletetestimonial//${testimonialsId?._id}`
      );
      if (res.status === 200) {
        alert("Delete Successfully");
        handleClose5();
        fetchTestimonials();
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  const updateStatus = async (testimonialsId) => {
    try {
      const config = {
        url: "/productstatus",
        method: "put",
        baseURL: "https://lehaim.in/api/testimonials",
        headers: { "content-type": "application/json" },
        data: {
          id: testimonialsId,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Status update");
        window.location.reload(true);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  return (
    <div>
      <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Testimonials</h2>
          <div className="d-flex gap-3">
            <button className="admin-add-btn" onClick={handleShow3}>
              Add Testimonials
            </button>
          </div>
        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>
                  <div style={{ width: "200px" }}>Name</div>
                </th>
                <th>
                  <div style={{ width: "200px" }}>Image</div>
                </th>
                <th>
                  <div style={{ width: "200px" }}>Specification</div>
                </th>
                <th>
                  {" "}
                  <div style={{ width: "400px" }}>Description</div>
                </th>

                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, i) => {
                return (
                  <>
                    <tr key={item?._id}>
                      <td>{i + 1}</td>
                      <td style={{ paddingTop: "10px" }}>{item?.name}</td>
                      <td style={{ paddingTop: "10px" }}>
                        <img
                          src={`https://lehaim.in/HomeServices/${item?.serviceimg}`}
                          alt="pic"
                          style={{ width: "75px", height: "75px" }}
                        />
                      </td>
                      <td style={{ paddingTop: "10px" }}>
                        {item?.specification}
                      </td>
                      <td style={{ paddingTop: "10px" }}>
                        {parse(`<div>${item?.description}</div>`)}
                      </td>

                      <td>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <BiSolidEdit
                              className=""
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                color: "rgb(101, 177, 69)",
                              }}
                              onClick={() => {
                                handleShow4();
                                settestimonialsId(item);
                              }}
                            />{" "}
                          </div>
                          <div>
                            <AiFillDelete
                              className=""
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                color: "rgb(25, 107, 156)",
                              }}
                              onClick={() => {
                                handleShow5();
                                settestimonialsId(item);
                              }}
                            />
                          </div>
                          <Button
                            className="active"
                            variant={
                              item.status === "Inactive" ? "success" : "danger"
                            }
                            onClick={() => updateStatus(item?._id)}
                          >
                            {item.status === "Inactive"
                              ? "Activate"
                              : "Inactivate"}
                          </Button>
                          {/* <button>Active</button> */}
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* Add Package modal for Slider */}
        <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
          <Modal.Header closeButton>
            <Modal.Title>Add Testimonials</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Name</label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="vi_0"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setServiceimg(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Specification</label>
                <input
                  type="text"
                  placeholder="Enter Specification"
                  className="vi_0"
                  value={specification}
                  onChange={(e) => setSpecification(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Description</label>

                <CKEditor editor={ClassicEditor} onChange={handleChange} />
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={LatestBlog}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal for Slider */}
        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              Edit Testimonial
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Name</label>
                <input
                  type="text"
                  placeholder={testimonialsId?.name}
                  className="vi_0"
                  // value={name1}
                  onChange={(e) => setName1(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setServiceimg1(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Specification</label>
                <input
                  type="text"
                  placeholder={testimonialsId?.specification}
                  className="vi_0"
                  // value={specification1}
                  onChange={(e) => setSpecification1(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Description</label>

                <CKEditor
                  editor={ClassicEditor}
                  data={testimonialsId?.description}
                  onChange={handleChange1}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={UpdateLatestBlog}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Delet Package modal for Slider */}
        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={DeleteLatestBlog}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default DocTestimonial;
