import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useStepContext } from "@mui/material";
import axios from "axios";
import parse from "html-react-parser";

const AdminServices = () => {
  // Ck Editor Code
  const [Desc, setDesc] = useState();
  const [Desc1, setDesc1] = useState();

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setDesc(data);
  };

  const handleChange1 = (e, editor) => {
    const data = editor.getData();
    setDesc1(data);
  };

  // Add modal
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal =
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delet modal
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [serviceName, setserviceName] = useState("");
  // const [serviceDescription, setServiceDescription] = useState("")
  const [serviceImage, setserviceImage] = useState("");

  // const formData = new FormData()

  const Servicedetails = async () => {
    try {
      const config = {
        url: "/home/createservices",
        method: "post",
        baseURL: "https://lehaim.in/api/homeservices",
        headers: { "content-type": "multipart/form-data" },
        data: {
          serviceName: Desc1,
          serviceDescription: Desc,
          serviceImage: serviceImage,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.status);
        handleClose3();
        getService();
        setserviceName("");
        setserviceImage("");
        setDesc("");
        setDesc1("");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  const [Service, setService] = useState([]);
  const getService = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/homeservices/home/getservices"
      );
      if (res.status === 201) {
        setService(res.data.sucess);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  console.log("Servicemmmm", Service);

  const [HomeServiceId, setHomeServiceId] = useState({});

  const EditServiceDatails = async () => {
    try {
      const config = {
        url: "/home/updateservices/" + HomeServiceId?._id,
        method: "put",
        baseURL: "https://lehaim.in/api/homeservices",
        headers: { "content-type": "multipart/form-data" },
        data: {
          serviceName: Desc1,
          serviceDescription: Desc,
          serviceImage: serviceImage,
        },
      };
      let res = await axios(config);
      if (res.status === 201) {
        alert(res.data.sucess);
        handleClose4();
        getService();
        setserviceName("");
        setserviceImage("");
        setDesc("");
        setDesc1("");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  const DeleteHomeSlider = async () => {
    try {
      let res = await axios.delete(
        `https://lehaim.in/api/homeservices/home/deleteservices/${HomeServiceId?._id}`
      );
      if (res.status === 201) {
        alert(res.data.message);
        getService();
        handleClose5();
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  };
  console.log("HomeServiceId", HomeServiceId);
  useEffect(() => {
    getService();
  }, []);

  return (
    <div>
      {/* <div className="col-lg-4 d-flex justify-content-center">
        <div class="input-group ">
          <span class="input-group-text" id="basic-addon1">
            <BsSearch />
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="Search..."
            aria-describedby="basic-addon1"
          />
        </div>
      </div> */}

      <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Feature Slider</h2>
          <div className="d-flex gap-3">
            <button className="admin-add-btn" onClick={handleShow3}>
              Add Feature
            </button>
          </div>
        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Feature Name</th>
                <th>Description</th>
                <th>Image</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {Service?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td style={{ paddingTop: "10px" }}>
                      {parse(`<div>${item?.serviceName}</div>`)}
                    </td>
                    <td style={{ paddingTop: "10px" }}>
                      {parse(`<div>${item?.serviceDescription}</div>`)}
                    </td>
                    <td style={{ paddingTop: "10px" }}>
                      <img
                        style={{ width: "150px", height: "100px" }}
                        src={`https://lehaim.in/HomeServices/${item?.serviceImage}`}
                        alt=""
                      />
                    </td>

                    <td>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <BiSolidEdit
                            className=""
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "rgb(101, 177, 69)",
                            }}
                            onClick={() => {
                              handleShow4();
                              setHomeServiceId(item);
                            }}
                          />{" "}
                        </div>
                        <div>
                          <AiFillDelete
                            className=""
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "rgb(25, 107, 156)",
                            }}
                            onClick={() => {
                              handleShow5();
                              setHomeServiceId(item);
                              // setDatas(item?._id);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* Add Package modal for Slider */}
        <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
          <Modal.Header closeButton>
            <Modal.Title>Add Feature Slider</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Feature Name</label>
                {/* <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  onChange={(e) => setserviceName(e.target.value)}
                /> */}
                <CKEditor editor={ClassicEditor} onChange={handleChange1} />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Feature Description</label>

                <CKEditor editor={ClassicEditor} onChange={handleChange} />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Service Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setserviceImage(e.target.files[0])}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={Servicedetails}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal for Slider */}

        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              Edit Feature Slider
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Feature Name</label>
                {/* <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  onChange={(e) => setserviceName(e.target.value)}
                /> */}
                <CKEditor editor={ClassicEditor} onChange={handleChange1} />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Feature Description</label>

                <CKEditor editor={ClassicEditor} onChange={handleChange} />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Feature Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setserviceImage(e.target.files[0])}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={EditServiceDatails}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Delet Package modal for Slider */}
        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={DeleteHomeSlider}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AdminServices;
