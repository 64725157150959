import React, { useState, useEffect } from "react";
import Aos from "aos";
import "../Styles/about.css";

import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import { FaGreaterThan } from "react-icons/fa";
import { PiGreaterThanBold } from "react-icons/pi";
// import {Container} from "bootstrap"
import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

import { PiLineVertical } from "react-icons/pi";
import { Container } from "react-bootstrap";

import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import axios from "axios";
import parse from "html-react-parser";

const About = () => {
  let [acc1, setacc1] = useState(true);
  let [acc2, setacc2] = useState(false);
  let [acc3, setacc3] = useState(false);
  let [acc4, setacc4] = useState(false);
  let [acc5, setacc5] = useState(false);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  useEffect(() => {
    Aos.init();
    window.scroll(0, 0);
  });

  // Frontend Integration

  //Get(Banner)

  const [Banner, setBanner] = useState([]);
  const getAboutBanner = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/aboutbanner/getaboutbanner"
      );
      if (res.status === 201) {
        setBanner(res.data.data1);
      }
    } catch (error) {
      console.log("data not found");
    }
  };

  //Get(Banner Who we are)

  const [AboutContent1, setAboutContent1] = useState([]);
  console.log("AboutContent1", AboutContent1);

  const getAdminContent1 = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/aboutbannercontents/getaboutbannercontents"
      );
      if (res.status === 201) {
        setAboutContent1(res.data.data1);
      }
    } catch (error) {
      console.log(error, "not able to fetch the data");
    }
  };

  console.log("AboutContent1: ", getAdminContent1);

  //Get(About Category)

  const [selectedcategory1, setselectedcategory1] = useState({});
  console.log("ccccccccc", selectedcategory1?.bannerText);

  const [selectedcategory, setselectedcategory] = useState();
  const [Category, setCategory] = useState([]);
  const getAboutCategory = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/categorybanner/getaboutcategory"
      );
      if (res.status === 201) {
        setCategory(res.data.data1);
        setselectedcategory1(res.data.data1?.[0]);
      }
    } catch (error) {
      console.log(error, "data some data");
    }
  };

  useEffect(() => {
    if (selectedcategory1) {
      setselectedcategory(selectedcategory1?.bannerText);
    } else {
      setselectedcategory();
    }
  }, [selectedcategory1]);

  console.log("selectedcategory111111", selectedcategory1);

  console.log("selectedcategoryyyyyyyyy", selectedcategory);
  // const Categorysata = Category?.[0]?.map((item) => item.bannerText);
  // console.log("Categorysata",Categorysata);
  console.log("Category: ", Category);

  useEffect(() => {
    getAboutBanner();
    getAdminContent1();
    getAboutCategory();
    getContactDetails();
  }, []);

  const [ContactDetails, setContactDetails] = useState([]);
  const conatactEmergency = ContactDetails.map((ele) => ele?.phone);
  const conatactEmergency1 = ContactDetails.map((ele) => ele?.altphone);
  console.log("ContactDetails: ", ContactDetails);
  const getContactDetails = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/contactdetails/getcontactdetails"
      );
      if (res.status === 201) {
        setContactDetails(res.data.data1);
        console.log(res.data.data1);
      }
    } catch (error) {
      console.log(error, "data some data");
    }
  };

  return (
    <div>
      {/* <section id='about'>
                <div className='abtheading'>
                    <h1>About</h1>
                    <p>Home/About</p>
                </div>
            </section> */}

      {Banner?.map((item) => {
        console.log(item?.bannerImg);

        return (
          <section
            id="about"
            style={{
              backgroundImage: `url(https://lehaim.in/AboutBanner/${item?.bannerImg})`,
            }}
          >
            <div className="container ">
              <div className="breadcrumbt">
                <div>
                  <h1 className="abtus-heading">{item?.bannerText}</h1>
                </div>
                <div className="mbl-responsive-breadcrumbs">
                  <Breadcrumb>
                    <FontAwesomeIcon
                      icon={faHouse}
                      style={{
                        fontSize: "14px",
                        marginTop: "0px",
                        color: "rgb(25, 107, 156)",
                        padding: "5px",
                      }}
                    />
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>About</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>
          </section>
        );
      })}

      <section id="about1">
        <div className="container">
          <div>
            <div className="row" data-aos="zoom-in" data-aos-duration="3000">
              <div
                className="col-md-3"
                data-aos="zoom-in"
                data-aos-duration="3000"
              >
                <div className="buttongroup">
                  {Category?.map((item) => {
                    return (
                      <div>
                        <button
                          onClick={() => {
                            setselectedcategory(item?.bannerText);
                          }}
                        >
                          <PiLineVertical className="verticalbar" />

                          {/* who we are category text */}

                          <span className="buttname">{item?.bannerText}</span>
                          <span className="greaterthan">
                            <PiGreaterThanBold />
                          </span>
                        </button>
                        <hr />
                      </div>
                    );
                  })}
                </div>

                <div className="ec mt-3 mb-5">
                  <div className="row border">
                    <div className="emergency">
                      <div className="col-md-3 ">
                        <img
                          src="/Assets/emgimg.png"
                          alt=""
                          className="emgimg"
                        />
                      </div>
                      <div className="col-md-9">
                        <div className="emgcontent">
                          <p>
                            <b>Emergency Case</b>
                          </p>
                          <p>{conatactEmergency}</p>
                          <p>{conatactEmergency1}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className='limitedoffer mt-5 '>
                  <img src="/Assets/limitedoffer.jpg" alt="" />
                  <button>SEE DETAILS 
                     <PiGreaterThanBold className='seedetailsicon' />
                     </button>
                  
                 </div> */}
              </div>
              {AboutContent1?.filter(
                (ele) => ele.category === selectedcategory
              )?.map((item) => {
                return (
                  <div className="col-md-9 ">
                    {/* title */}
                    <div>
                      <h1 className="contentheading">{item?.bannerTitle}</h1>
                    </div>

                    {/* category description1 */}
                    <div className="contentparagraph">
                      {parse(`<div>${item?.bannerDescription1}</div>`)}
                    </div>

                    <div className="row">
                      <div className="col-md-6 mt-2">
                        {/* category image */}
                        <img
                          src={`https://lehaim.in/AboutBannerContents/${item?.bannerImg}`}
                          className="about-category-image mt-4"
                          style={{ height: "250px", width: "400px" }}
                        />
                      </div>

                      <div className="col-md-6 mt-2">
                        {/* category video */}
                        <video
                          controls
                          muted
                          loop
                          className="video-rot"
                          key={item?.bannerVideo}
                        >
                          <source
                            src={`https://lehaim.in/AboutBannerContents/${item?.bannerVideo}`}
                            type="video/mp4"
                            className="about-category-video"
                            style={{ height: "350px", width: "400px" }}
                          />
                        </video>
                        {/* <div className='contentimg'>  <img src="/Assets/about2.jpg" alt="" /></div> */}
                      </div>
                    </div>

                    {/* description 2 */}
                    <div className="contentparagraph">
                      <p>{parse(`<div>${item?.bannerDescription2}</div/>`)}</p>
                    </div>

                    {/* <div>
                    <div className="row">
                      <div className="col-md-6 patients text-start">
                        <p><FaGreaterThan className='golu'/><b>Keep Patients First</b></p>
                        <p><FaGreaterThan className='golu'/><b>Keep Everyone Safe</b></p>
                        <p><FaGreaterThan className='golu'/><b>Work Together</b></p>
                      </div>
                      <div className="col-md-6 patients text-start">
                        <p><FaGreaterThan className='golu'/><b>Pursue Excellence</b></p>
                        <p><FaGreaterThan className='golu'/><b>Manage Your Resources</b></p>
                        <p><FaGreaterThan className='golu'/><b>Keep Learning</b></p>
                      </div>
                    </div>
                    <div  className='contentparagraph'><p>Hymenaeos eros. Nisi mauris et adipisc iquam class bibendum non mattis fusceut perspiciatis undeuisque. Maecenas. Eros mus. Hymenaeos eros. Nisi mauris et adipiscing. Aliquam class bibendum mattis fusceut persecenas. Quisque. Maecenas. Eros mus. Hymenaeos eros. Nisi mauris et adipisc iquam class bibendum non mattis fusceut perspiciatis undeuisque. Quisque. Maecenas. Eros mus. Hy perspiciatise bibendum non mattis .</p></div>
                  </div> */}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
