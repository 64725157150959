import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";

const AdminOurspecialist = () => {
  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delet modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [Image, setImage] = useState("");
  const [Name, setName] = useState("");
  const [Profession, setProfession] = useState("");

  // const getOurTeam=async()=>{
  //   try {
  //     let res=await axios.get("https://lehaim.in/api/homeourteam/getourteam");
  //     if(res.status===201){
  //       setOurTeam(res.data.success);
  //     }
  //   } catch (error) {
  //     alert(error.response.data.error);
  //   }
  // }

  // const getOurTeam = async ()=>{
  //   try {
  //     let res = await axios.get("https://lehaim.in/api/homeourteam/getourteams")
  //     if(res.status === 201){
  //       setOurTeam(res.data.success)
  //     }
  //   } catch (error) {

  //   }
  // }

  //Get
  const [OurTeam, setOurTeam] = useState([]);
  const getOurTeam = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/homeourteam/getourteams"
      );
      if (res.status === 201) {
        setOurTeam(res.data.success);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  console.log("Servicemmmm", setOurTeam);

  //Post
  const PostOurTeam = async () => {
    try {
      const config = {
        url: "/createourteam",
        method: "post",
        baseURL: "https://lehaim.in/api/homeourteam",
        headers: { "content-type": "multipart/form-data" },
        data: {
          image: Image,
          title: Name,
          profession: Profession,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.status);
        handleClose3();
        getOurTeam();
        setImage("");
        setName("");
        setProfession("");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  // console.log("orteamm",OurTeam);

  //Edit

  const [OurTeamId, setOurTeamId] = useState({});
  const EditOurTeam = async () => {
    try {
      const config = {
        url: "/updateourteam/" + OurTeamId?._id,
        method: "put",
        baseURL: "https://lehaim.in/api/homeourteam",
        headers: { "content-type": "multipart/form-data" },
        data: {
          image: Image,
          title: Name,
          profession: Profession,
        },
      };
      let res = await axios(config);
      if (res.status === 201) {
        alert(res.data.success);
        handleClose4();
        getOurTeam();
        setImage("");
        setName("");
        setProfession("");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  console.log("orteamm", OurTeamId);

  const DeleteOurTeam = async () => {
    try {
      let res = await axios.delete(
        `https://lehaim.in/api/homeourteam/deleteourteam/${OurTeamId?._id}`
      );
      if (res.status === 201) {
        alert(res.data.message);
        getOurTeam();
        handleClose5();
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  };
  useEffect(() => {
    getOurTeam();
  }, []);

  //avtivate & inactivate from admin panel(logic)

  const updateStatus = async (OurTeamId) => {
    try {
      const config = {
        url: "/productstatus",
        method: "put",
        baseURL: "https://lehaim.in/api/homeourteam",
        headers: { "content-type": "application/json" },
        data: {
          id: OurTeamId,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Status update");
        window.location.reload(true);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  return (
    <div>
      {/* <div className="col-lg-4 d-flex justify-content-center">
        <div class="input-group ">
          <span class="input-group-text" id="basic-addon1">
            <BsSearch />
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="Search..."
            aria-describedby="basic-addon1"
          />
        </div>
      </div> */}

      <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Our Specialist Slider</h2>
          <div className="d-flex gap-3">
            <button className="admin-add-btn" onClick={handleShow3}>
              Add Our specialist
            </button>
          </div>
        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>Sl.No</th>
                {/* <th>Title</th>                  */}
                <th>Image</th>
                <th>Specialist Name</th>
                <th>Profession</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {OurTeam?.map((item, i) => {
                return (
                  <>
                    <tr>
                      <td>{i + 1}</td>
                      {/* <td style={{ paddingTop: "10px" }}>Title</td>                 */}
                      <td>
                        <img
                          src={`https://lehaim.in/OurTeam/${item?.image}`}
                          alt="pic"
                          style={{ width: "75px", height: "75px" }}
                        />
                      </td>
                      <td style={{ paddingTop: "10px" }}>{item?.title}</td>
                      <td style={{ paddingTop: "10px" }}>{item?.profession}</td>
                      <td>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <BiSolidEdit
                              className=""
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                color: "rgb(101, 177, 69)",
                              }}
                              onClick={() => {
                                setOurTeamId(item);
                                handleShow4();
                              }}
                            />{" "}
                          </div>

                          <div>
                            <AiFillDelete
                              className=""
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                color: "rgb(25, 107, 156)",
                              }}
                              onClick={() => {
                                handleShow5();
                                setOurTeamId(item);
                              }}
                            />
                          </div>

                          {/* <button>Active</button> */}
                          <Button
                            className="active"
                            variant={
                              item.status === "Inactive" ? "success" : "danger"
                            }
                            onClick={() => updateStatus(item?._id)}
                          >
                            {item.status === "Inactive"
                              ? "Activate"
                              : "Inactivate"}
                          </Button>
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
            {/* )) : (<p>loading</p>)} */}
          </Table>
        </div>

        {/* Add Package modal for Slider */}
        <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
          <Modal.Header closeButton>
            <Modal.Title> Department Banner</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Specialist Title</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter Banner Title"
                    // onChange={(e) => setBannerText(e.target.value)}
                    />
                  </div>
                </div> */}

            <div className="row">
              <div className="do-sear mt-2">
                <label>Specialist Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Specialist Name</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Specialist Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Specialist Profession</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Specialist Profession"
                  onChange={(e) => setProfession(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={PostOurTeam}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal for Slider */}
        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              Edit Specialist Slider
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Specialist Title</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter Banner Title"
                    // onChange={(e) => setBannerText(e.target.value)}
                    />
                  </div>
                </div> */}

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Specialist Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Specialist Name</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Specialist Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Specialist Profession</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Specialist Profession"
                  onChange={(e) => setProfession(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button variant="" className="modal-add-btn" onClick={EditOurTeam}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Delet Package modal for Slider */}
        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={DeleteOurTeam}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AdminOurspecialist;
