import React, { useEffect, useState } from "react";
import "../Styles/footer.css";
import axios from "axios";
import parse from "html-react-parser";

const FooterPrivacyPolicy = () => {
  //Get

  const [PrivacyPolicy, setPrivacyPolicy] = useState([]);
  const getPrivacyPolicy = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/footerprivacycontents/getfooterprivacycontents"
      );
      if (res.status === 201) {
        setPrivacyPolicy(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  return (
    <div className="container privacy-police">
      <p className="privacy">PRIVACY POLICY</p>
      {PrivacyPolicy?.map((item) => {
        return (
          <div className="row container">
            <div className="col-md-12">
              {parse(`<div>${item?.privacyDescription}</div>`)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FooterPrivacyPolicy;
