// import React from 'react'

// const AdminDepartment = () => {
//   return (
//     <div>AdminDepartment</div>
//   )
// }

// export default AdminDepartment\

import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";
const AdminDepartment = () => {
  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delet modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [Title, setTitle] = useState("");
  const [Icon, setIcon] = useState("");
  const [Image, setImage] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [discription, setdiscription] = useState("");
  const handleChange1 = (e, editor) => {
    const data = editor.getData();
    setdiscription(data);
  };
  const handleChange2 = (e, editor) => {
    const data = editor.getData();
    setdiscription(data);
  };
  const HomeDepartment = async () => {
    try {
      const config = {
        url: "/craetehomedepartment",
        method: "post",
        baseURL: "https://lehaim.in/api/homedepartment",
        headers: { "content-type": "multipart/form-data" },
        data: {
          departmentIcon: Icon,
          departmentImage: Image,
          departmentName: departmentName,
          discription: discription,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.status);
        handleClose3();
        getHomeDepartment();
        setTitle("");
        setIcon("");
        setImage("");
        setDepartmentName("");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  const [Department, setDepartment] = useState([]);
  console.log("Department", Department);

  const getHomeDepartment = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/homedepartment/gethomedepartment"
      );
      if (res.status === 201) {
        setDepartment(res.data.status);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  const [HomeDepartmentId, setHomeDepartmentId] = useState({});
  const [Icon1, setIcon1] = useState("");
  const [Image1, setImage1] = useState("");
  console.log(HomeDepartmentId, "HomeDepartmentId");
  const UpdateHomeDepartment = async () => {
    try {
      const config = {
        url: "/updatehomedepartment/" + HomeDepartmentId?._id,
        method: "put",
        baseURL: "https://lehaim.in/api/homedepartment",
        headers: { "content-type": "multipart/form-data" },
        data: {
          departmentIcon: Icon1,
          departmentImage: Image1,
          departmentName: departmentName,
          discription: discription,
        },
      };
      let res = await axios(config);
      if (res.status === 201) {
        alert("Update successfully");
        handleClose4();
        getHomeDepartment();
        setTitle("");
        setIcon("");
        setImage("");
        setDepartmentName("");
        window.location.reload(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const DeleteHomeDepartment = async () => {
    try {
      let res = await axios.delete(
        `https://lehaim.in/api/homedepartment/deletehomedepartment/${HomeDepartmentId?._id}`
      );
      if (res.status === 201) {
        alert(res.data.message);
        getHomeDepartment();
        handleClose5();
      }
    } catch (error) {
      alert(error.response?.data?.error || "An error occurred");
    }
  };
  useEffect(() => {
    getHomeDepartment();
  }, []);

  //avtivate & inactivate from admin panel(logic)

  const updateStatus = async (HomeDepartmentId) => {
    try {
      const config = {
        url: "/productstatus",
        method: "put",
        baseURL: "https://lehaim.in/api/homedepartment",
        headers: { "content-type": "application/json" },
        data: {
          id: HomeDepartmentId,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Status update");
        window.location.reload(true);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  return (
    <div>
      {/* <div className="col-lg-4 d-flex justify-content-center">
        <div class="input-group ">
          <span class="input-group-text" id="basic-addon1">
            <BsSearch />
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="Search..."
            aria-describedby="basic-addon1"
          />
        </div>
      </div> */}

      <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Services</h2>
          <div className="d-flex gap-3">
            <button className="admin-add-btn" onClick={handleShow3}>
              Add Service
            </button>
          </div>
        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Icon</th>
                <th>Image</th>
                <th>Service Name</th>
                <th>
                  <div style={{ width: "300px" }}>Description</div>
                </th>
                <th>Action</th>
              </tr>
            </thead>

            {Department ? (
              Department.map((item, i) => (
                <tbody key={item.id}>
                  <tr>
                    <td>{i + 1}</td>

                    <td>
                      <img
                        src={`https://lehaim.in/HomeDepartment/${item?.departmentIcon}`}
                        alt="pic"
                        style={{ width: "75px", height: "75px" }}
                      />
                    </td>
                    <td>
                      <img
                        src={`https://lehaim.in/HomeDepartment/${item?.departmentImage}`}
                        alt="pic"
                        style={{ width: "75px", height: "75px" }}
                      />
                    </td>
                    <td style={{ paddingTop: "10px" }}>
                      {item?.departmentName}
                    </td>
                    <td style={{ paddingTop: "10px" }}>
                      {" "}
                      {parse(`<div>${item?.discription}</div>`)}
                    </td>
                    <td>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <BiSolidEdit
                            className=""
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "rgb(101, 177, 69)",
                            }}
                            onClick={() => {
                              handleShow4();
                              setHomeDepartmentId(item);
                            }}
                          />{" "}
                        </div>
                        <div>
                          <AiFillDelete
                            className=""
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "rgb(25, 107, 156)",
                            }}
                            onClick={() => {
                              handleShow5();
                              setHomeDepartmentId(item);
                            }}
                          />
                        </div>

                        <Button
                          className="active"
                          variant={
                            item.status === "Inactive" ? "success" : "danger"
                          }
                          onClick={() => updateStatus(item?._id)}
                        >
                          {item.status === "Inactive"
                            ? "Activate"
                            : "Inactivate"}
                        </Button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))
            ) : (
              <p>loading</p>
            )}
          </Table>
        </div>

        {/* Add Package modal for Slider */}
        <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
          <Modal.Header closeButton>
            <Modal.Title>Add Service</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label> Service Icon</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  onChange={(e) => setIcon(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label> Service Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Service Name</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  onChange={(e) => setDepartmentName(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Service Discription</label>

                <CKEditor editor={ClassicEditor} onChange={handleChange1} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={HomeDepartment}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal for Slider */}
        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>Edit Service</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Service Icon</label>
                <input
                  type="file"
                  name="departmentIcon"
                  id=""
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  onChange={(e) => setIcon1(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Service Image</label>
                <input
                  type="file"
                  id=""
                  className="vi_0"
                  name="departmentImage"
                  onChange={(e) => setImage1(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Service Name</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  onChange={(e) => setDepartmentName(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Service Discription</label>

                <CKEditor editor={ClassicEditor} onChange={handleChange2} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={UpdateHomeDepartment}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Delet Package modal for Slider */}
        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={DeleteHomeDepartment}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AdminDepartment;
