import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FiPhoneCall } from "react-icons/fi";
import { PiDotsNineThin } from "react-icons/pi";
import { MdOutlineMenu } from "react-icons/md";
import "../Styles/Header.css";
import { Button } from "react-bootstrap";

const HeaderResponsive = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="afpajdpjo">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "15px",
        }}
      >
        <div>
          <img
            className="img-fluid"
            src="../Images/headerlogo.png"
            alt="Logo Img"
            style={{ width: "150px", height: "50px" }}
          />
        </div>
        <div className="d-flex justify-content-end flex-column">
          <MdOutlineMenu style={{ fontSize: "40px" }} onClick={handleShow} />
        </div>
      </div>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Menu</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body style={{ width: "164px", marginLeft: "84px" }}>
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <Nav.Link
              href="/"
              className="nav-content-color"
              style={{ color: "#196b9c" }}
            >
              HOME
            </Nav.Link>
            <Nav.Link
              href="/about-s"
              className="nav-content-color"
              style={{ color: "#196b9c" }}
            >
              ABOUT US
            </Nav.Link>
            <Nav.Link
              href="/service"
              className="nav-content-color"
              style={{ color: "#196b9c" }}
            >
              SERVICES
            </Nav.Link>
            <Nav.Link
              href="/gallery"
              className="nav-content-color"
              style={{ color: "#196b9c" }}
            >
              GALLERY
            </Nav.Link>
            <Nav.Link
              href="/allblog"
              className="nav-content-color"
              style={{ color: "#196b9c" }}
            >
              BLOG
            </Nav.Link>
            <Nav.Link
              href="/faq"
              className="nav-content-color"
              style={{ color: "#196b9c" }}
            >
              FAQ
            </Nav.Link>
            <Nav.Link
              href="/contact"
              className="nav-content-color"
              style={{ color: "#196b9c" }}
            >
              CONTACT US
            </Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default HeaderResponsive;
