import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import parse from "html-react-parser";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AdminTestimonial = () => {
  const [startDate, setStartDate] = useState(new Date());
  // Ck Editor Code
  const [Desc, setDesc] = useState();

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setDesc(data);
  };

  const handleChange1 = (e, editor) => {
    const data = editor.getData();
    setdiscription(data);
  };
  const handleChange2 = (e, editor) => {
    const data = editor.getData();
    setdiscription(data);
  };
  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delete modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [BlogImg, setBlogImg] = useState("");
  // const [BlogTitle, setBlogTitle] = useState("");
  const [BlogIcon, setBlogIcon] = useState("");
  // const [BlogDate, setBlogDate] = useState("");
  const [BlogBookmark, setBlogBookmark] = useState("");
  const [discription, setdiscription] = useState("");
  const LatestBlog = async () => {
    try {
      const config = {
        url: "/createlatestblog",
        method: "post",
        baseURL: "https://lehaim.in/api/homelatestblog",
        headers: { "content-type": "multipart/form-data" },
        data: {
          blogTitle: Desc,
          blogDate: startDate,
          blogBookmark: BlogBookmark,
          blogImage: BlogImg,
          blogIcon: BlogIcon,
          discription: discription,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.status);
        handleClose3();
        getLatestBlog();
        setBlogImg("");
        setBlogBookmark("");
        setBlogIcon("");
        setStartDate("");
        setDesc("");
        setdiscription(" ");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  const [LatestBlogs, setLatestBlogs] = useState([]);
  const getLatestBlog = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/homelatestblog/getlatestblog"
      );
      if (res.status === 201) {
        setLatestBlogs(res.data.status);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  const [LatestBlogId, setLatestBlogId] = useState({});
  const UpdateLatestBlog = async () => {
    try {
      const config = {
        url: "/updatelatestblog/" + LatestBlogId?._id,
        method: "put",
        baseURL: "https://lehaim.in/api/homelatestblog",
        headers: { "content-type": "multipart/form-data" },
        data: {
          blogTitle: Desc,
          blogDate: startDate,
          blogBookmark: BlogBookmark,
          blogImage: BlogImg,
          blogIcon: BlogIcon,
          discription: discription,
        },
      };
      let res = await axios(config);
      if (res.status === 201) {
        alert(res.data.sucess);
        window.location.reload(true);
        handleClose4();
        getLatestBlog();
        setBlogImg("");
        setBlogBookmark("");
        setBlogIcon("");
        setStartDate("");
        setDesc("");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  console.log("sqswwd", LatestBlogId);

  const DeleteLatestBlog = async () => {
    try {
      let res = await axios.delete(
        `https://lehaim.in/api/homelatestblog/deletelatestblog/${LatestBlogId?._id}`
      );
      if (res.status === 201) {
        alert(res.data.message);
        getLatestBlog();
        handleClose5();
        window.location.reload(true);
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  };
  useEffect(() => {
    getLatestBlog();
  }, []);

  //avtivate & inactivate from admin panel(logic)

  const updateStatus = async (LatestBlogsId) => {
    try {
      const config = {
        url: "/productstatus",
        method: "put",
        baseURL: "https://lehaim.in/api/homelatestblog",
        headers: { "content-type": "application/json" },
        data: {
          id: LatestBlogsId,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Status update");
        window.location.reload(true);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  return (
    <div>
      {/* <div className="col-lg-4 d-flex justify-content-center">
        <div class="input-group ">
          <span class="input-group-text" id="basic-addon1">
            <BsSearch />
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="Search..."
            aria-describedby="basic-addon1"
          />
        </div>
      </div> */}

      <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Latest Blogs</h2>
          <div className="d-flex gap-3">
            <button className="admin-add-btn" onClick={handleShow3}>
              Add Latest Blogs
            </button>
          </div>
        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Image</th>
                <th>
                  <div style={{ width: "200px" }}>Title</div>
                </th>
                <th>
                  {" "}
                  <div style={{ width: "400px" }}>Discription</div>
                </th>
                <th>Icon</th>
                <th>Date</th>
                <th>Bookmark</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {LatestBlogs?.reverse().map((item, i) => {
                return (
                  <>
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        <img
                          src={`https://lehaim.in/Blog/${item?.blogImage}`}
                          alt="pic"
                          style={{ width: "75px", height: "75px" }}
                        />
                      </td>
                      <td style={{ paddingTop: "10px" }}>
                        {parse(`<div>${item?.blogTitle}</div>`)}
                      </td>
                      <td style={{ paddingTop: "10px" }}>
                        {parse(`<div>${item?.discription}</div>`)}
                      </td>
                      <td>
                        <img
                          src={`https://lehaim.in/Blog/${item?.blogIcon}`}
                          alt="pic"
                          style={{ width: "75px", height: "75px" }}
                        />{" "}
                      </td>
                      <td style={{ paddingTop: "10px" }}>{`${new Date(
                        item?.blogDate
                      ).getDate()}-${
                        new Date(item?.blogDate).getMonth() + 1
                      }-${new Date(item?.blogDate).getFullYear()}`}</td>
                      <td style={{ paddingTop: "10px" }}>
                        {item?.blogBookmark}
                      </td>
                      <td>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <BiSolidEdit
                              className=""
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                color: "rgb(101, 177, 69)",
                              }}
                              onClick={() => {
                                setLatestBlogId(item);
                                handleShow4();
                              }}
                            />{" "}
                          </div>
                          <div>
                            <AiFillDelete
                              className=""
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                color: "rgb(25, 107, 156)",
                              }}
                              onClick={() => {
                                handleShow5();
                                setLatestBlogId(item);
                              }}
                            />
                          </div>
                          {/* <button>Active</button> */}
                          <Button
                            className="active"
                            variant={
                              item.status === "Inactive" ? "success" : "danger"
                            }
                            onClick={() => updateStatus(item?._id)}
                          >
                            {item.status === "Inactive"
                              ? "Activate"
                              : "Inactivate"}
                          </Button>
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* Add Package modal for Slider */}
        <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
          <Modal.Header closeButton>
            <Modal.Title>Latest Blogs and Articles</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Blog Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setBlogImg(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Blog Title</label>

                <CKEditor editor={ClassicEditor} onChange={handleChange} />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Blog Discription</label>

                <CKEditor editor={ClassicEditor} onChange={handleChange1} />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Blog Icon</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setBlogIcon(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label style={{ marginRight: "10px" }}>Blog Date</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Blog Bookmark</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Blog Bookmark"
                  onChange={(e) => setBlogBookmark(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={LatestBlog}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal for Slider */}
        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              Edit Latest Blog and Article
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Blog Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setBlogImg(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Blog Title</label>

                <CKEditor editor={ClassicEditor} onChange={handleChange} />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Blog Discription</label>

                <CKEditor editor={ClassicEditor} onChange={handleChange2} />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Blog Icon</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setBlogIcon(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Blog Date</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  // onChange={(e) => setBannerText(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Blog Bookmark </label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Blog Bookmark"
                  onChange={(e) => setBlogBookmark(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={UpdateLatestBlog}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Delet Package modal for Slider */}
        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={DeleteLatestBlog}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AdminTestimonial;
