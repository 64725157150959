import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";
import parse from "html-react-parser";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";

const AdminContactgeneralenquiry = () => {
  // Ck Editor Code (Contact form message desccription)
  const [Desc, setDesc] = useState();

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setDesc(data);
  };

  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delete modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  //Get

  const [EnquiryDetails, setEnquiryDetails] = useState([]);

  const getContactEnquiryForm = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/contactenquiryform/getcontactenquiryform"
      );
      if (res.status === 201) {
        setEnquiryDetails(res.data.data1);
      }
    } catch (error) {
      console.log(error, "data some data");
    }
  };

  //Delete
  const [ContactFormId, setContactFormId] = useState();
  const deleteContactEnquiryForm = async () => {
    try {
      let res = await axios.delete(
        `https://lehaim.in/api/contactenquiryform/deletecontactenquiryform/${ContactFormId?._id}`
      );
      if (res.status === 201) {
        alert(res.data.message);
        handleClose5();
        getContactEnquiryForm();
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  useEffect(() => {
    getContactEnquiryForm();
  }, []);

  return (
    <div>
      {/* <div className="col-lg-4 d-flex justify-content-center">
      <div class="input-group ">
        <span class="input-group-text" id="basic-addon1">
          <BsSearch />
        </span>
        <input
          type="text"
          class="form-control"
          placeholder="Search..."
          aria-describedby="basic-addon1"
        />
      </div>
    </div> */}

      <div className="customerhead p-2">
        <h2 className="text-center">Enquiry Details List</h2>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Message</th>
                <th>Service</th>
                <th>Register Date</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {EnquiryDetails?.slice()
                .reverse()
                .map((item, i) => {
                  console.log(item, "reverse");
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td style={{ paddingTop: "10px" }}>{item?.name}</td>
                      <td style={{ paddingTop: "10px" }}>{item?.phone}</td>
                      <td style={{ paddingTop: "10px" }}>{item?.email}</td>
                      <td style={{ paddingTop: "10px" }}>{item?.message}</td>
                      <td style={{ paddingTop: "10px" }}>{item?.service}</td>
                      <td style={{ paddingTop: "10px" }}>
                        {moment(item?.createdAt).format("LLL")}
                      </td>
                      <td>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <AiFillDelete
                              className=""
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                color: "rgb(25, 107, 156)",
                              }}
                              onClick={() => {
                                handleShow5();
                                setContactFormId(item);
                                // // setDatas(item?._id);
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>

        {/*Delet Package modal for Slider */}
        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={deleteContactEnquiryForm}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AdminContactgeneralenquiry;
