import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import { BiTime } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
import { CiLocationOn, CiCalendarDate } from "react-icons/ci";
import Card from "react-bootstrap/Card";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Slider from "react-slick";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import OwlCarousel from "react-owl-carousel";
import { IoIosContact } from "react-icons/io";
import parse from "html-react-parser";
import { BsCalendar3 } from "react-icons/bs";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "../Styles/blog.css";

export const Blog = () => {
  const options = {
    margin: 20,
    responsiveClass: true,
    nav: true,
    dots: true,
    autoplay: true,
    autoplayTimeout: 3000,
    loop: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };

  const location = useLocation();
  let initialItem = location.state?.item || null;

  const [item, setItem] = useState(initialItem);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [item]);

  //console.log("item dddddd", item);

  //const  = async (id) => {

  const [LatestBlogs, setLatestBlogs] = useState([]);
  const getLatestBlog = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/homelatestblog/getlatestblog"
      );
      if (res.status === 201) {
        setLatestBlogs(res.data.status);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  const [Banner, setBanner] = useState(null);

  const getBlogBanner = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/blogbanner/getblogbanner"
      );
      if (res.status === 201) {
        setBanner(res.data.data1);
      }
    } catch (error) {
      console.log(error, "data some data");
    }
  };

  // console.log("Banner: ", Banner);

  useEffect(() => {
    getLatestBlog();
    getBlogBanner();
    window.scrollTo(0, 0);
  }, []);

  const handleCarouselItemClick = (clickedItem) => {
    setItem(clickedItem);
  };

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      {Banner?.map((item) => {
        return (
          <section
            id="about"
            style={{
              backgroundImage: `url(https://lehaim.in/BlogBanner/${item?.bannerImg})`,
            }}
          >
            <div className="container ">
              <div className="breadcrumbt">
                <div>
                  <h1 className="abtus-heading">{item?.bannerText}</h1>
                </div>

                <div>
                  <Breadcrumb>
                    <FontAwesomeIcon
                      icon={faHouse}
                      style={{
                        fontSize: "14px",
                        marginTop: "0px",
                        color: "rgb(25, 107, 156)",
                        padding: "5px",
                      }}
                    />
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Blog</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>
          </section>
        );
      })}

      <Container className="mt-5">
        <div>
          <h4
            className="text-center fw-bold mb-4"
            style={{ color: "rgb(25, 107, 156)" }}
          >
            {parse(`<div>${item.blogTitle?.slice(0, 80)}</div>`)}
          </h4>
          <div className="row mt-4 ">
            <div className="col-md-6 col-lg-4 d-flex justify-content-center mb-3 ">
              <div>
                <img
                  className="img-fluid"
                  src={`https://lehaim.in/Blog/${item.blogImage}`}
                  alt="sdbwjdb"
                />

                <div className="d-flex justify-content-evenly align-items-center">
                  <p>
                    <span className="fw-bold">
                      <IoIosContact
                        style={{
                          fontSize: "40px",
                          marginTop: "0px",
                          color: "rgb(25, 107, 156)",
                          padding: "5px",
                        }}
                      />{" "}
                    </span>{" "}
                    ADMIN{" "}
                  </p>
                  <p>
                    <span className="fw-bold">
                      <BsCalendar3
                        style={{
                          fontSize: "30px",
                          marginTop: "0px",
                          color: "rgb(25, 107, 156)",
                          padding: "5px",
                        }}
                      />
                    </span>
                    {`${new Date(item.blogDate).getDate()}-${
                      new Date(item.blogDate).getMonth() + 1
                    }-${new Date(item.blogDate).getFullYear()}`}
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-md-6 col-lg-8 mb-3 mt-3"
              style={{ fontSize: "18px" }}
            >
              <p style={{ textAlign: "justify" }}>
                {parse(`<div>${item?.discription}</div>`)}
              </p>
            </div>
          </div>
        </div>
      </Container>

      <Container>
        <OwlCarousel
          className="owl-theme"
          dots={true}
          loop={false}
          autoPlay={true}
          margin={10}
          nav
          {...options}
        >
          {LatestBlogs?.filter((item) => item.status == "Inactive").map(
            (blogitem, i) => {
              return (
                <div className=" item  mt-3 mb-3" key={item?._id}>
                  <div
                    onClick={() => {
                      handleCarouselItemClick(blogitem);
                    }}
                  >
                    <Card
                      className="m-auto"
                      style={{
                        width: "17rem",
                        height: "300px",
                        margin: "10px",
                      }}
                    >
                      <Card.Img variant="top" />

                      <img
                        style={{ width: "100%", height: "150px" }}
                        src={`https://lehaim.in/Blog/${blogitem?.blogImage}`}
                        alt="Doctor-img"
                      />

                      <Card.Body className="p-3">
                        <Card.Text>
                          {" "}
                          <div
                            className="fw-semibold"
                            style={{
                              textDecoration: "none",
                              color: "rgb(25, 107, 156)",
                            }}
                          >
                            {parse(
                              `<div style="font-size:15px">${blogitem?.blogBookmark}</div>`
                            )}
                          </div>
                        </Card.Text>
                        <p className="fw-light">{`${new Date(
                          blogitem?.blogDate
                        ).getDate()}-${
                          new Date(blogitem?.blogDate).getMonth() + 1
                        }-${new Date(blogitem?.blogDate).getFullYear()}`}</p>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              );
            }
          )}
        </OwlCarousel>
      </Container>
    </div>
  );
};
