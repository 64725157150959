import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import parse from "html-react-parser";
import { Card, Container } from "react-bootstrap";
import { IoIosContact } from "react-icons/io";
import { BsCalendar3 } from "react-icons/bs";
import moment from "moment";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function AllBlogs() {
  //Get(Blog Banner)
  const [Banner, setBanner] = useState(null);
  const navigate = useNavigate();
  const getBlogBanner = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/blogbanner/getblogbanner"
      );
      if (res.status === 201) {
        setBanner(res.data.data1);
        console.log(res.data.data1);
      }
    } catch (error) {
      console.log(error, "data some data");
    }
  };

  // useState for View More & View Less Button
  const blogSectionRef = useRef(null);
  const [visibleItems, setVisibleItems] = useState(4);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleView = () => {
    if (isExpanded) {
      setVisibleItems(4); // Show only 4 items
      blogSectionRef.current?.scrollIntoView({ behavior: "smooth" }); //for scrolling
    } else {
      setVisibleItems(BlogDetails.length); // Show all items
      blogSectionRef.current?.scrollIntoView({ behavior: "smooth" }); //for scrolling
    }
    setIsExpanded(!isExpanded);
  };

  //Get(Blog Details)
  const [BlogDetails, setBlogDetails] = useState([]);
  console.log(BlogDetails.length, "lenght");
  const getBlogDetails = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/blogdetails/getblogdetails"
      );
      if (res.status === 201) {
        setBlogDetails(res.data.data1);
      }
    } catch (error) {
      console.log(error, "data some data");
    }
  };

  const [productblog, setproductblog] = useState({});

  const [LatestBlogs, setLatestBlogs] = useState([]);
  console.log(LatestBlogs, "LatestBlogs");
  const getLatestBlog = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/homelatestblog/getlatestblog"
      );
      if (res.status === 201) {
        setLatestBlogs(res.data.status);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getBlogBanner();
    getLatestBlog();
  }, []);

  useEffect(() => {
    if (productblog) {
      window.scroll(1, 1); //for scrolling the page
      getBlogDetails();
    }
  }, [productblog]);

  return (
    <div>
      {Banner?.map((item) => {
        return (
          <section
            id="about"
            style={{
              backgroundImage: `url(https://lehaim.in/BlogBanner/${item?.bannerImg})`,
            }}
          >
            <div className="container">
              <div className="breadcrumbt">
                <div>
                  <h1 className="abtus-heading">{item?.bannerText}</h1>
                </div>
                <div className="mbl-responsive-breadcrumbs">
                  <Breadcrumb>
                    <FontAwesomeIcon
                      icon={faHouse}
                      style={{
                        fontSize: "14px",
                        marginTop: "0px",
                        color: "rgb(25, 107, 156)",
                        padding: "5px",
                      }}
                    />
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Blog</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>
          </section>
        );
      })}

      <Container>
        <p className="blog-header mt-5">All Blogs</p>

        {BlogDetails.length > 4 && (
          <Button type="button" className="blogbtn" onClick={handleToggleView}>
            {isExpanded ? "View Less" : "View More"}
          </Button>
        )}

        <div className="row" ref={blogSectionRef}>
          {productblog?.selectBlog ? (
            <Container className="mt-5">
              <div>
                <h4
                  className="text-center fw-bold mb-4 blog-title"
                  style={{ color: "rgb(25, 107, 156)" }}
                >
                  {parse(
                    `<div>${productblog?.selectBlog?.blogTitle?.slice(
                      0,
                      80
                    )}.....</div>`
                  )}
                </h4>

                <div className="row mt-4">
                  <div className="col-md-6 col-lg-4 d-flex justify-content-center mb-3">
                    <div>
                      <img
                        className="img-fluid"
                        src={`https://lehaim.in/Blog/${productblog?.selectBlog?.blogImage}`}
                        alt="sdbwjdb"
                      />

                      <div className="d-flex justify-content-evenly align-items-center">
                        <p>
                          <span className="fw-bold">
                            <IoIosContact
                              style={{
                                fontSize: "40px",
                                marginTop: "0px",
                                color: "rgb(25, 107, 156)",
                                padding: "5px",
                              }}
                            />{" "}
                          </span>{" "}
                          ADMIN{" "}
                        </p>
                        <p className="calendar-clr">
                          <span className="fw-bold">
                            <BsCalendar3
                              style={{
                                fontSize: "30px",
                                marginTop: "0px",
                                color: "rgb(25, 107, 156)",
                                padding: "5px",
                              }}
                            />
                          </span>
                          {`${new Date(
                            productblog?.selectBlog?.blogDate
                          ).getDate()}-${
                            new Date(
                              productblog?.selectBlog?.blogDate
                            ).getMonth() + 1
                          }-${new Date(
                            productblog?.selectBlog?.blogDate
                          ).getFullYear()}`}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-md-6 col-lg-8 mb-3 mt-3"
                    style={{ fontSize: "18px" }}
                  >
                    <p style={{ textAlign: "justify" }}>
                      {parse(
                        `<div>${productblog?.selectBlog?.discription}</div>`
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </Container>
          ) : (
            ""
          )}

          {LatestBlogs?.filter((item) => item?.status == "Inactive")?.map(
            (item, i) => {
              return (
                <div className="col-md-3 mt-3 mb-2" key={i}>
                  <div onClick={() => setproductblog(item)}>
                    <Card
                      className="m-auto"
                      style={{
                        width: "17rem",
                        minHeight: "250px",
                        margin: "10px",
                      }}
                      onClick={() => {
                        navigate("/blog", { state: { item } });
                      }}
                    >
                      <Card.Img variant="top" />
                      <img
                        style={{ width: "100%", height: "150px" }}
                        src={`https://lehaim.in/Blog/${item?.blogImage}`}
                        alt="Doctor-img"
                      />
                      <Card.Body className="p-3">
                        <Card.Text>
                          <div
                            className="fw-semibold blog-titlee"
                            style={{
                              textDecoration: "none",
                              color: "rgb(25, 107, 156)",
                            }}
                          >
                            {parse(
                              `<div style="font-size:15px">${item?.blogBookmark}</div>`
                            )}
                          </div>
                        </Card.Text>
                        <div className="d-flex justify-content-center">
                          <span className="fw-bold">
                            <BsCalendar3
                              style={{
                                fontSize: "30px",
                                marginTop: "0px",
                                color: "rgb(25, 107, 156)",
                                padding: "5px",
                              }}
                            />
                          </span>
                          <p className="calendar-colour">
                            {moment(item?.createdAt).format("DD-MM-YYYY")}
                          </p>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </Container>
    </div>
  );
}

export default AllBlogs;
