import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image, Form } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import axios from "axios";
import parse from "html-react-parser";
const Admin_Service_Details = () => {
  // Ck Editor Code

  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delete modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  //Frontend Integration

  const [Desc, setDesc1] = useState("");

  const handleChange1 = (e, editor) => {
    const data = editor.getData();
    setDesc1(data);
  };
  const [ServiceCategory, setServiceCategory] = useState("");
  const AddServiceDetails = async () => {
    try {
      const config = {
        url: "/createservicedetails",
        method: "post",
        baseURL: "https://lehaim.in/api/servicedetails",
        headers: { "content-type": "application/json" },
        data: {
          selectService: ServiceCategory,
          description: Desc,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.status);
        handleClose3();
        getServiceDetais();
        setDesc1("");
        setServiceCategory();
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  const [ServiceDetails, setServiceDetails] = useState([]);
  const getServiceDetais = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/servicedetails/getservicedetails"
      );
      if (res.status === 201) {
        setServiceDetails(res.data.data1);
      }
    } catch (error) {
      console.log(error, "data some data");
    }
  };

  console.log("ServiceDetails66464646: ", ServiceDetails);

  //Edit
  const [ServiceDetailsId, setServiceDetailsId] = useState({});

  const editServiceDetails = async () => {
    try {
      const config = {
        url: "/updateservicedetails/" + ServiceDetailsId?._id,
        method: "put",
        baseURL: "https://lehaim.in/api/servicedetails",
        headers: { "content-type": "application/json" },
        data: {
          selectService: ServiceCategory,
          description: Desc,
        },
      };

      let res = await axios(config);
      if (res.status === 201) {
        alert(res.data.sucess);
        handleClose4();
        getServiceDetais();
        setDesc1("");
        setServiceCategory("");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  //Delete

  const deleteServiceDetails = async () => {
    try {
      let res = await axios.delete(
        `https://lehaim.in/api/servicedetails/deleteservicedetails/${ServiceDetailsId?._id}`
      );
      if (res.status === 201) {
        alert(res.data.message);
        getServiceDetais();
        handleClose5();
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  const [Department, setDepartment] = useState([]);
  console.log("Department", Department);

  const getHomeDepartment = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/homedepartment/gethomedepartment"
      );
      if (res.status === 201) {
        setDepartment(res.data.status);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  useEffect(() => {
    getHomeDepartment();
    getServiceDetais();
  }, []);

  return (
    <div>
      <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Choose Services</h2>
          <div className="d-flex gap-3">
            <button className="admin-add-btn" onClick={handleShow3}>
              Add Services
            </button>
          </div>
        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Services</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {ServiceDetails.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>

                    <td style={{ paddingTop: "10px" }}>
                      {item?.selectService?.departmentName}
                    </td>

                    <td style={{ paddingTop: "10px" }}>
                      {parse(`<div>${item?.description}</div>`)}
                    </td>

                    <td>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <BiSolidEdit
                            className=""
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "rgb(101, 177, 69)",
                            }}
                            onClick={() => {
                              handleShow4();
                              setServiceDetailsId(item);
                            }}
                          />{" "}
                        </div>
                        <div>
                          <AiFillDelete
                            className=""
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "rgb(25, 107, 156)",
                            }}
                            onClick={() => {
                              handleShow5();
                              setServiceDetailsId(item);
                              // setDatas(item?._id);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* Add Package modal for Slider */}
        <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
          <Modal.Header closeButton>
            <Modal.Title>Add Services</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Services</label>
                <Form.Select
                  onChange={(e) => setServiceCategory(e.target.value)}
                >
                  <option>select</option>
                  {Department?.map((item, i) => {
                    return (
                      <option value={item?._id}>{item?.departmentName}</option>
                    );
                  })}
                </Form.Select>
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Description</label>

                <CKEditor editor={ClassicEditor} onChange={handleChange1} />
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={AddServiceDetails}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal for Slider */}
        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>Edit Services</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {/* <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Category Title</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  onChange={(e) => setBannerTitle(e.target.value)}
                />
              </div>
            </div> */}

            <div className="row">
              <div className="do-sear mt-2">
                <label>Services</label>
                <Form.Select
                  onChange={(e) => setServiceCategory(e.target.value)}
                >
                  <option>select</option>
                  {Department?.map((item, i) => {
                    return (
                      <option value={item?._id}>{item?.departmentName}</option>
                    );
                  })}
                </Form.Select>
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Descrption</label>
                <CKEditor editor={ClassicEditor} onChange={handleChange1} />
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={editServiceDetails}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Delet Package modal for Slider */}
        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={deleteServiceDetails}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Admin_Service_Details;
