import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import parse from "html-react-parser";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const AdminEmail = () => {
  const [data, setData] = useState([]);
  console.log(data, "ijeqoije");
  const fetchEmails = async () => {
    try {
      const response = await axios.get(
        "https://lehaim.in/api/testimonials/getemail"
      );
      console.log(response.data.data);
      setData(response.data.data);
    } catch (error) {
      console.error(
        "Error fetching testimonials",
        error.response?.data?.error || "An error occurred"
      );
    }
  };

  useEffect(() => {
    fetchEmails();
  }, []);

  return (
    <div>
      <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Email</h2>
        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>
                  <div style={{ width: "200px", textAlign: "center" }}>
                    Email
                  </div>
                </th>
                <th>
                  <div style={{ width: "200px", textAlign: "center" }}>
                    Date
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data
                ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .map((item, i) => {
                  return (
                    <tr key={item?._id}>
                      <td>{i + 1}</td>
                      <td style={{ paddingTop: "10px", textAlign: "center" }}>
                        {item?.email}
                      </td>
                      <td style={{ paddingTop: "10px", textAlign: "center" }}>
                        {moment(item?.createdAt).format("LLL")}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default AdminEmail;
