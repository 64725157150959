import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image, Form } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import axios from "axios";
import parse from "html-react-parser";

const AdminAboutwhoweare = () => {
  // Ck Editor Code
  const [Desc1, setDesc1] = useState("");
  const [Desc2, setDesc2] = useState("");

  const handleChange1 = (e, editor) => {
    const data = editor.getData();
    setDesc1(data);
  };

  const handleChange2 = (e, editor) => {
    const data = editor.getData();
    setDesc2(data);
  };
  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delete modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  //Frontend Integration

  const [BannerCategoryText, setBannerCategoryText] = useState("");
  const [BannerTitle, setBannerTitle] = useState("");
  const [BannerImage, setBannerImage] = useState("");
  const [BannerVideo, setBannerVideo] = useState("");

  //Category Get API

  const [Category, setCategory] = useState([]);
  const getAboutCategory = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/categorybanner/getaboutcategory"
      );
      if (res.status === 201) {
        setCategory(res.data.data1);
      }
    } catch (error) {
      console.log(error, "data some data");
    }
  };

  //Post

  const postAdminContent1 = async () => {
    try {
      const config = {
        url: "/createaboutbannercontents",
        method: "post",
        baseURL: "https://lehaim.in/api/aboutbannercontents",
        headers: { "content-type": "multipart/form-data" },
        data: {
          category: BannerCategoryText,
          bannerTitle: BannerTitle,
          bannerDescription1: Desc1,
          bannerImg: BannerImage,
          bannerVideo: BannerVideo,
          bannerDescription2: Desc2,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.status);
        handleClose3();
        getAdminContent1();
        // getAboutCategory();

        setBannerCategoryText("");
        setBannerTitle("");
        setBannerImage("");
        setBannerVideo("");
        setDesc1("");
        setDesc2("");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  //Get

  const [AboutContent1, setAboutContent1] = useState([]);

  const getAdminContent1 = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/aboutbannercontents/getaboutbannercontents"
      );
      if (res.status === 201) {
        setAboutContent1(res.data.data1);
      }
    } catch (error) {
      console.log(error, "not able to fetch the data");
    }
  };

  console.log("AboutContent1: ", getAdminContent1);

  //Edit
  const [AboutContent1Id, setAboutContent1Id] = useState({});

  const editAdminContent1 = async () => {
    try {
      const config = {
        url: "/updateaboutbannercontent/" + AboutContent1Id?._id,
        method: "put",
        baseURL: "https://lehaim.in/api/aboutbannercontents",
        headers: { "content-type": "multipart/form-data" },
        data: {
          category: BannerCategoryText,
          bannerTitle: BannerTitle,
          bannerDescription1: Desc1,
          bannerImg: BannerImage,
          bannerVideo: BannerVideo,
          bannerDescription2: Desc2,
        },
      };

      let res = await axios(config);
      if (res.status === 201) {
        alert(res.data.success);
        window.location.reload(true);
        handleClose4();
        getAdminContent1();

        setBannerCategoryText("");
        setBannerTitle("");
        setDesc1("");
        setBannerImage("");
        setBannerVideo("");
        setDesc2("");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  //Delete

  const deleteAdminContent1 = async () => {
    try {
      let res = await axios.delete(
        `https://lehaim.in/api/aboutbannercontents/deleteaboutbannercontent/${AboutContent1Id?._id}`
      );
      if (res.status === 201) {
        alert(res.data.message);
        getAdminContent1();
        handleClose5();
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  useEffect(() => {
    getAdminContent1();
    getAboutCategory();
  }, []);

  return (
    <div>
      {/* <div className="col-lg-4 d-flex justify-content-center">
        <div class="input-group ">
          <span class="input-group-text" id="basic-addon1">
            <BsSearch />
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="Search..."
            aria-describedby="basic-addon1"
          />
        </div>
      </div> */}

      <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Choose Category</h2>
          <div className="d-flex gap-3">
            <button className="admin-add-btn" onClick={handleShow3}>
              Add Category
            </button>
          </div>
        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Category</th>
                <th>Title</th>
                <th>Description1</th>
                <th>Image</th>
                <th>Video</th>
                <th>Description2</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {AboutContent1?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>

                    <td style={{ paddingTop: "10px" }}>{item?.category}</td>

                    <td style={{ paddingTop: "10px" }}>{item?.bannerTitle}</td>

                    <td style={{ paddingTop: "10px" }}>
                      {parse(`<div>${item?.bannerDescription1}</div/>`)}
                    </td>

                    <td>
                      <img
                        src={`https://lehaim.in/AboutBannerContents/${item?.bannerImg}`}
                        alt="pic"
                        style={{ width: "75px", height: "75px" }}
                      />{" "}
                    </td>

                    <td>
                      <video controls autoPlay muted loop className="video">
                        <source
                          src={`https://lehaim.in/AboutBannerContents/${item?.bannerVideo}`}
                          type="video/mp4"
                        />
                      </video>{" "}
                    </td>

                    <td style={{ paddingTop: "10px" }}>
                      {parse(`<div>${item?.bannerDescription2}</div/>`)}
                    </td>
                    <td>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <BiSolidEdit
                            className=""
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "rgb(101, 177, 69)",
                            }}
                            onClick={() => {
                              handleShow4();
                              setAboutContent1Id(item);
                            }}
                          />{" "}
                        </div>
                        <div>
                          <AiFillDelete
                            className=""
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "rgb(25, 107, 156)",
                            }}
                            onClick={() => {
                              handleShow5();
                              setAboutContent1Id(item);
                              // setDatas(item?._id);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* Add Package modal for Slider */}
        <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
          <Modal.Header closeButton>
            <Modal.Title>Add Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Category</label>
                <Form.Select
                  onChange={(e) => setBannerCategoryText(e.target.value)}
                >
                  <option>select</option>
                  {Category?.map((item) => {
                    return (
                      <option value={item?.bannerText}>
                        {item?.bannerText}
                      </option>
                    );
                  })}
                </Form.Select>
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Category Title</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Category Title"
                  onChange={(e) => setBannerTitle(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Description1</label>

                <CKEditor editor={ClassicEditor} onChange={handleChange1} />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Image</label>
                <input
                  type="file"
                  name=""
                  accept="image/*"
                  id=""
                  className="vi_0"
                  onChange={(e) => setBannerImage(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Video</label>
                <input
                  type="file"
                  name=""
                  accept="video/*"
                  id=""
                  className="vi_0"
                  onChange={(e) => setBannerVideo(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Description2</label>

                <CKEditor editor={ClassicEditor} onChange={handleChange2} />
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={postAdminContent1}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal for Slider */}
        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>Edit Category</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {/* <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Category Title</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  onChange={(e) => setBannerTitle(e.target.value)}
                />
              </div>
            </div> */}

            <div className="row">
              <div className="do-sear mt-2">
                <label>Category</label>
                <Form.Select
                  onChange={(e) => setBannerCategoryText(e.target.value)}
                >
                  <option>select</option>
                  {Category?.map((item) => {
                    return (
                      <option value={item?.bannerText}>
                        {item?.bannerText}
                      </option>
                    );
                  })}
                </Form.Select>
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Category Title</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Category Title"
                  onChange={(e) => setBannerTitle(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Descrption1</label>
                <CKEditor editor={ClassicEditor} onChange={handleChange1} />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setBannerImage(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Video</label>
                <input
                  type="file"
                  name=""
                  accept="video/*"
                  id=""
                  className="vi_0"
                  onChange={(e) => setBannerVideo(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Descrption2</label>
                <CKEditor editor={ClassicEditor} onChange={handleChange2} />
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={editAdminContent1}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Delet Package modal for Slider */}
        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={deleteAdminContent1}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AdminAboutwhoweare;
