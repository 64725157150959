import React, { useEffect, useState } from "react";
import "../Styles/ourteam.css";
import OwlCarousel from "react-owl-carousel";
import axios from "axios";

const OurTeam = () => {
  const options = {
    margin: 20,
    responsiveClass: true,
    nav: true,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 3,
      },
    },
  };

  //Get(Department)

  const [OurTeam, setOurTeam] = useState([]);
  const getOurTeam = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/homeourteam/getourteams"
      );
      if (res.status === 201) {
        setOurTeam(res.data.success);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  useEffect(() => {
    getOurTeam();
  }, []);

  //Active logic(admin panel)

  const isActive = OurTeam.some((item) => item.status === "Inactive");
  console.log("Department=============>", OurTeam);

  return (
    <div>
      {isActive ? (
        <>
          <div className="ourteam-title">
            <div className="our-team" style={{ color: "#196b9c" }}>
              Our Team
            </div>
          </div>

          <div className="ourteam-card-cor">
            <div className="container">
              <OwlCarousel
                className="owl-theme "
                dots={true}
                loop={false}
                margin={8}
                {...options}
              >
                {OurTeam?.map((item) => {
                  if (item?.status == "Inactive") {
                    return (
                      <div
                        className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-b857cf6 mb-2"
                        data-id="b857cf6"
                        data-element_type="column"
                        key={item?._id}
                      >
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-2fdbb4f elementor-widget elementor-widget-feature"
                            data-id="2fdbb4f"
                            data-element_type="widget"
                            data-widget_type="feature.default"
                          >
                            <div className="elementor-widget-container">
                              <div
                                className="featured-item style-1 shattle"
                                style={{
                                  padding: "218px 40px",
                                  width: "360px",
                                  // height:"250px !important",
                                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                }}
                              >
                                <div
                                  className="ht-shape-btn"
                                  style={{ zIndex: "9" }}
                                >
                                  <svg
                                    width="111"
                                    height="111"
                                    viewBox="0 0 111 111"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M0 0C19.33 0 35 15.67 35 35V41C35 60.33 50.67 76 70 76H76C95.33 76 111 91.67 111 111V0H0Z"
                                      fill="none"
                                    ></path>
                                  </svg>
                                </div>
                                <div
                                  className="featured-number"
                                  style={{
                                    position: "absolute",
                                    right: "7px",
                                    top: "2px",
                                  }}
                                >
                                  <img
                                    src="../Images/share.png"
                                    alt=""
                                    style={{ width: "30px", height: "30px" }}
                                  />
                                </div>
                                <div className="featured-icon">
                                  <i className="flaticon flaticon-stethoscope-3">
                                    <img
                                      src={`https://lehaim.in/OurTeam/${item?.image}`}
                                      className="c-img"
                                      style={{
                                        width: "360px",
                                        height: "300px",
                                        arginRight: "-48px",
                                        marginRight: "-49px",
                                        borderRadius: "30px",
                                      }}
                                    />
                                  </i>
                                </div>
                                <div className="featured-desc">
                                  <h4
                                    style={{
                                      color: "#196b9c",
                                      width: "",
                                      position: "absolute",
                                      bottom: "80px",
                                      textAlign: "left",
                                    }}
                                    className="c-head our-team-dr"
                                  >
                                    {item?.title}
                                  </h4>

                                  <p
                                    className="c-para"
                                    style={{
                                      textAlign: "left",
                                      padding: "5px 10px",
                                      borderRadius: "10px",
                                      backgroundColor: "#f1faff",
                                      fontWeight: "bold",
                                      width: "60%",
                                      position: "absolute",
                                      bottom: "25px",
                                    }}
                                  >
                                    {item?.profession}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}

                {/* ============================ */}
                {/* <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-b857cf6" data-id="b857cf6" data-element_type="column"  data-aos="zoom-out" data-aos-duration="3000">
      <div className="elementor-widget-wrap elementor-element-populated">
        <div className="elementor-element elementor-element-2fdbb4f elementor-widget elementor-widget-feature" data-id="2fdbb4f" data-element_type="widget" data-widget_type="feature.default">
          <div className="elementor-widget-container">
            <div className="featured-item style-1 shattle" style={{padding:"200px 40px", width:"270px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"   }}>
              <div className="ht-shape-btn" style={{zIndex:"9"}}>
                <svg width="111" height="111" viewBox="0 0 111 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M0 0C19.33 0 35 15.67 35 35V41C35 60.33 50.67 76 70 76H76C95.33 76 111 91.67 111 111V0H0Z" fill="none"></path>
                </svg>
              </div>
              <div className="featured-number" style={{position:"absolute",right:"7px",top:"2px"}}>
                <img src='../Images/share.png' alt='' style={{width:"30px",height:"30px"}}/>
              </div>
              <div className="featured-icon">
                <i className="flaticon flaticon-stethoscope-3">
                <img src="../Images/smith.jpg" className="c-img" style={{  
     width: "269px",
     height: "300px",
     arginRight: "-48px",
     marginRight: "-49px",
     borderRadius: "30px"}}/>
                </i>
              </div>
              <div className="featured-desc">
                  <h4 style={{color:"#196b9c",width: "",position: "absolute", bottom: "55px",textAlign: "left"}} className="c-head our-team-dr">Dr Blanco Smith</h4>
            
                <p className="c-para" style={{    textAlign: "left",padding:"5px 10px", borderRadius:"10px",backgroundColor:"#f1faff",fontWeight:"bold",
    width:"60%",
    position: "absolute",
    bottom:"1px"}}>Our Professional</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
                {/* =========================================== */}
                {/* <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-b857cf6" data-id="b857cf6" data-element_type="column"  data-aos="zoom-out" data-aos-duration="3000">
      <div className="elementor-widget-wrap elementor-element-populated">
        <div className="elementor-element elementor-element-2fdbb4f elementor-widget elementor-widget-feature" data-id="2fdbb4f" data-element_type="widget" data-widget_type="feature.default">
          <div className="elementor-widget-container">
            <div className="featured-item style-1 shattle" style={{padding:"200px 40px", width:"270px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"   }}>
              <div className="ht-shape-btn" style={{zIndex:"9"}}>
                <svg width="111" height="111" viewBox="0 0 111 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M0 0C19.33 0 35 15.67 35 35V41C35 60.33 50.67 76 70 76H76C95.33 76 111 91.67 111 111V0H0Z" fill="none"></path>
                </svg>
              </div>
              <div className="featured-number" style={{position:"absolute",right:"7px",top:"2px"}}>
                <img src='../Images/share.png' alt='' style={{width:"30px",height:"30px"}}/>
              </div>
              <div className="featured-icon">
                <i className="flaticon flaticon-stethoscope-3">
                <img src="../Images/sofia.jpg" className="c-img" style={{  
     width: "269px",
     height: "300px",
     arginRight: "-48px",
     marginRight: "-49px",
     borderRadius: "30px"}}/>
                </i>
              </div>
              <div className="featured-desc">
                  <h4 style={{color:"#196b9c",width: "",position: "absolute", bottom: "55px",textAlign: "left"}} className="c-head our-team-dr">Dr Whitney Rain</h4>
            
                <p className="c-para" style={{    textAlign: "left",padding:"5px 10px", borderRadius:"10px",backgroundColor:"#f1faff",fontWeight:"bold",
    width:"60%",
    position: "absolute",
    bottom:"1px"}}>Our Professional</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
                {/* ================================ */}
                {/* <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-b857cf6" data-id="b857cf6" data-element_type="column"  data-aos="zoom-out" data-aos-duration="3000">
      <div className="elementor-widget-wrap elementor-element-populated">
        <div className="elementor-element elementor-element-2fdbb4f elementor-widget elementor-widget-feature" data-id="2fdbb4f" data-element_type="widget" data-widget_type="feature.default">
          <div className="elementor-widget-container">
            <div className="featured-item style-1 shattle" style={{padding:"200px 40px", width:"270px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"   }}>
              <div className="ht-shape-btn" style={{zIndex:"9"}}>
                <svg width="111" height="111" viewBox="0 0 111 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M0 0C19.33 0 35 15.67 35 35V41C35 60.33 50.67 76 70 76H76C95.33 76 111 91.67 111 111V0H0Z" fill="none"></path>
                </svg>
              </div>
              <div className="featured-number" style={{position:"absolute",right:"7px",top:"2px"}}>
                <img src='../Images/share.png' alt='' style={{width:"30px",height:"30px"}}/>
              </div>
              <div className="featured-icon">
                <i className="flaticon flaticon-stethoscope-3">
                <img src="../Images/lunariver.jpg" className="c-img" style={{  
     width: "269px",
     height: "300px",
     arginRight: "-48px",
     marginRight: "-49px",
     borderRadius: "30px"}}/>
                </i>
              </div>
              <div className="featured-desc">
                  <h4 style={{color:"#196b9c",width: "",position: "absolute", bottom: "55px",textAlign: "left"}} className="c-head our-team-dr">Dr Luna River</h4>
            
                <p className="c-para" style={{    textAlign: "left",padding:"5px 10px", borderRadius:"10px",backgroundColor:"#f1faff",fontWeight:"bold",
    width:"60%",
    position: "absolute",
    bottom:"1px"}}>Our Professional</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
                {/* ========================================== */}
                {/* <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-b857cf6" data-id="b857cf6" data-element_type="column"  data-aos="zoom-out" data-aos-duration="3000">
      <div className="elementor-widget-wrap elementor-element-populated">
        <div className="elementor-element elementor-element-2fdbb4f elementor-widget elementor-widget-feature" data-id="2fdbb4f" data-element_type="widget" data-widget_type="feature.default">
          <div className="elementor-widget-container">
            <div className="featured-item style-1 shattle" style={{padding:"200px 40px", width:"270px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"   }}>
              <div className="ht-shape-btn" style={{zIndex:"9"}}>
                <svg width="111" height="111" viewBox="0 0 111 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M0 0C19.33 0 35 15.67 35 35V41C35 60.33 50.67 76 70 76H76C95.33 76 111 91.67 111 111V0H0Z" fill="none"></path>
                </svg>
              </div>
              <div className="featured-number" style={{position:"absolute",right:"7px",top:"2px"}}>
                <img src='../Images/share.png' alt='' style={{width:"30px",height:"30px"}}/>
              </div>
              <div className="featured-icon">
                <i className="flaticon flaticon-stethoscope-3">
                <img src="../Images/jhonson.jpg" className="c-img" style={{  
     width: "269px",
     height: "300px",
     arginRight: "-48px",
     marginRight: "-49px",
     borderRadius: "30px"}}/>
                </i>
              </div>
              <div className="featured-desc">
                  <h4 style={{color:"#196b9c",width: "",position: "absolute", bottom: "55px",textAlign: "left"}} className="c-head our-team-dr">Dr Micheal Jhonson</h4>
            
                <p className="c-para" style={{    textAlign: "left",padding:"5px 10px", borderRadius:"10px",backgroundColor:"#f1faff",fontWeight:"bold",
    width:"60%",
    position: "absolute",
    bottom:"1px"}}>Our Professional</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
                {/* ======================================= */}
                {/* <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-b857cf6" data-id="b857cf6" data-element_type="column"  data-aos="zoom-out" data-aos-duration="3000">
      <div className="elementor-widget-wrap elementor-element-populated">
        <div className="elementor-element elementor-element-2fdbb4f elementor-widget elementor-widget-feature" data-id="2fdbb4f" data-element_type="widget" data-widget_type="feature.default">
          <div className="elementor-widget-container">
            <div className="featured-item style-1 shattle"  style={{padding:"200px 40px", width:"270px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"   }}>
              <div className="ht-shape-btn" style={{zIndex:"9"}}>
                <svg width="111" height="111" viewBox="0 0 111 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M0 0C19.33 0 35 15.67 35 35V41C35 60.33 50.67 76 70 76H76C95.33 76 111 91.67 111 111V0H0Z" fill="none"></path>
                </svg>
              </div>
              <div className="featured-number" style={{position:"absolute",right:"7px",top:"2px"}}>
                <img src='../Images/share.png' alt='' style={{width:"30px",height:"30px"}}/>
              </div>
              <div className="featured-icon">
                <i className="flaticon flaticon-stethoscope-3">
                <img src="../Images/haley.jpg" className="c-img" style={{  
     width: "269px",
     height: "300px",
     arginRight: "-48px",
     marginRight: "-49px",
     borderRadius: "30px"}}/>
                </i>
              </div>
              <div className="featured-desc">
                  <h4 style={{color:"#196b9c",width: "",position: "absolute", bottom: "55px",textAlign: "left"}} className="c-head our-team-dr">Dr Hailey Star</h4>
            
                <p className="c-para" style={{    textAlign: "left",padding:"5px 10px", borderRadius:"10px",backgroundColor:"#f1faff",fontWeight:"bold",
    width:"60%",
    position: "absolute",
    bottom:"1px"}}>Our Professional</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
                {/* ================================================= */}
                {/* <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-b857cf6" data-id="b857cf6" data-element_type="column"  data-aos="zoom-out" data-aos-duration="3000">
      <div className="elementor-widget-wrap elementor-element-populated">
        <div className="elementor-element elementor-element-2fdbb4f elementor-widget elementor-widget-feature" data-id="2fdbb4f" data-element_type="widget" data-widget_type="feature.default">
          <div className="elementor-widget-container">
            <div className="featured-item style-1 shattle" style={{padding:"200px 40px", width:"270px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"   }}>
              <div className="ht-shape-btn" style={{zIndex:"9"}}>
                <svg width="111" height="111" viewBox="0 0 111 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M0 0C19.33 0 35 15.67 35 35V41C35 60.33 50.67 76 70 76H76C95.33 76 111 91.67 111 111V0H0Z" fill="none"></path>
                </svg>
              </div>
              <div className="featured-number" style={{position:"absolute",right:"7px",top:"2px"}}>
                <img src='../Images/share.png' alt='' style={{width:"30px",height:"30px"}}/>
              </div>
              <div className="featured-icon">
                <i className="flaticon flaticon-stethoscope-3">
                <img src="../Images/daniel.jpg" className="c-img" style={{  
     width: "269px",
     height: "300px",
     arginRight: "-48px",
     marginRight: "-49px",
     borderRadius: "30px"}}/>
                </i>
              </div>
              <div className="featured-desc">
                  <h4 style={{color:"#196b9c",width: "",position: "absolute", bottom: "55px",textAlign: "left"}} className="c-head our-team-dr">Dr Danielle Fairy</h4>
            
                <p className="c-para" style={{    textAlign: "left",padding:"5px 10px", borderRadius:"10px",backgroundColor:"#f1faff",fontWeight:"bold",
    width:"60%",
    position: "absolute",
    bottom:"1px"}}>Our Professional</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
                {/* ============================================ */}
                {/* <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-b857cf6" data-id="b857cf6" data-element_type="column"  data-aos="zoom-out" data-aos-duration="3000">
      <div className="elementor-widget-wrap elementor-element-populated">
        <div className="elementor-element elementor-element-2fdbb4f elementor-widget elementor-widget-feature" data-id="2fdbb4f" data-element_type="widget" data-widget_type="feature.default">
          <div className="elementor-widget-container">
            <div className="featured-item style-1 shattle" style={{padding:"200px 40px", width:"270px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"   }}>
              <div className="ht-shape-btn" style={{zIndex:"9"}}>
                <svg width="111" height="111" viewBox="0 0 111 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M0 0C19.33 0 35 15.67 35 35V41C35 60.33 50.67 76 70 76H76C95.33 76 111 91.67 111 111V0H0Z" fill="none"></path>
                </svg>
              </div>
              <div className="featured-number" style={{position:"absolute",right:"7px",top:"2px"}}>
                <img src='../Images/share.png' alt='' style={{width:"30px",height:"30px"}}/>
              </div>
              <div className="featured-icon">
                <i className="flaticon flaticon-stethoscope-3">
                <img src="../Images/alice.jpg" className="c-img" style={{  
     width: "269px",
     height: "300px",
     arginRight: "-48px",
     marginRight: "-49px",
     borderRadius: "30px"}}/>
                </i>
              </div>
              <div className="featured-desc">
                  <h4 style={{color:"#196b9c",width: "",position: "absolute", bottom: "55px",textAlign: "left"}} className="c-head our-team-dr">Dr Alice Blue</h4>
            
                <p className="c-para" style={{    textAlign: "left",padding:"5px 10px", borderRadius:"10px",backgroundColor:"#f1faff",fontWeight:"bold",
    width:"60%",
    position: "absolute",
    bottom:"1px"}}>Our Professional</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
              </OwlCarousel>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default OurTeam;
