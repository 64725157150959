import React, { Suspense, useEffect, useState } from "react";
//import TopHeader from "./TopHeader";
import Header from "./Header";
import HeaderResponsive from "./HeaderResponsive";

import Carousel from "react-bootstrap/Carousel";
import { Container } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import Card from "react-bootstrap/Card";
import { IoCalendarOutline } from "react-icons/io5";
import { IoBookmarksOutline } from "react-icons/io5";
import "../Styles/home.css";
import Mainsection from "./Mainsection";
import ServicesHome from "./ServicesHome";
import ScrollingText from "./ScrollingText";
import OurTeam from "./OurTeam";
import LatestBlogs from "./LatestBlogs";
import parse from "html-react-parser";
import axios from "axios";
import { lazy } from "react";
import Testimonial from "./Testimonial";
// const ServicesHome = lazy(() => import("./ServicesHome"));
function Home() {
  const options = {
    margin: 20,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const [Banner, setBanner] = useState([]);
  const getHomeslider = async () => {
    try {
      let res = await axios.get("https://lehaim.in/api/homebanner/gethome");
      if (res.status === 201) {
        setBanner(res.data.data1);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getHomeslider();
  }, []);
  console.log("Banner", Banner);

  // ===================

  const [Service, setService] = useState([]);
  const getService = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/homeservices/home/getservices"
      );
      if (res.status === 201) {
        setService(res.data.sucess);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  // console.log("Servicemmmm", Service);
  useEffect(() => {
    getService();
  }, []);

  return (
    <div style={{ background: "#f1faff" }}>
      <div className="" style={{ backgroundColor: "#F1FAFF" }}>
        {/* <TopHeader /> */}

        <div className="container-fluid">
          <Carousel fade className="cor-top-home">
            {Banner?.map((item) => {
              return (
                <Carousel.Item className="carousel-item mt-5">
                  <img
                    src={`https://lehaim.in/HomeBanner/${item?.bannerImg}`}
                    alt=""
                    height="700px"
                    width="100%"
                    style={{ borderRadius: "42px" }}
                  />
                  <Carousel.Caption>
                    <div className="cor-image">
                      <h5 className="cor-heading">{item?.bannerTitle}</h5>
                    </div>
                    <div style={{ position: "relative", display: "flex" }}>
                      <a href="/about-s">
                        <div className="main">
                          <img
                            src="../Images/right-arrow.png"
                            alt="Icon"
                            style={{ textAlign: "center" }}
                            className="arrow-right-explore"
                          />
                          <svg
                            id="rotatingText"
                            viewBox="0 0 150 150"
                            width="150"
                            height="150"
                            className="rotating-text-mobile-res"
                          >
                            <defs>
                              <path
                                id="circle"
                                d="M 75, 75
            m -56.25, 0
            a 56.25, 56.25 0 1, 0 112.5, 0
            a 56.25, 56.25 0 1, 0 -112.5, 0"
                              ></path>
                            </defs>
                            <text>
                              <textPath
                                xlinkHref="#circle"
                                startOffset="0%"
                                className="text"
                                alignmentBaseline="middle"
                              >
                                EXPLORE MORE
                              </textPath>
                            </text>
                            <text>
                              <textPath
                                xlinkHref="#circle"
                                startOffset="50%"
                                className="text"
                                alignmentBaseline="middle"
                              >
                                EXPLORE MORE
                              </textPath>
                            </text>
                          </svg>
                        </div>
                      </a>
                      <div>
                        <p className="cor-paragraph">
                          {parse(`<div>${item?.bannerDescription}</div/>`)}
                        </p>
                      </div>
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}

            {/* <Carousel.Item className="carousel-item">
              <img
                src="../Images/home3.png"
                alt=""
                height="700px"
                width="100%"
                style={{ borderRadius: "42px" }}
              />
              <Carousel.Caption>
                <div className="cor-image" data-aos="zoom-out-in" data-aos-duration="2000">
                  <h5 className="cor-heading">
                    Nurturing Lives, Enriching Health
                  </h5>

                </div>
                <div style={{ position: "relative", display: "flex" }}>
                  <div className="main" data-aos="zoom-out" data-aos-duration="2000">
                    <img src="../Images/right-arrow.png" alt="Icon" style={{ textAlign: "center" }} className="arrow-right-explore" />
                    <svg id="rotatingText" viewBox="0 0 150 150" width="150" height="150" className="rotating-text-mobile-res">
                      <defs>
                        <path id="circle" d="M 75, 75
          m -56.25, 0
          a 56.25, 56.25 0 1, 0 112.5, 0
          a 56.25, 56.25 0 1, 0 -112.5, 0">
                        </path>
                      </defs>
                      <text>
                        <textPath xlinkHref="#circle" startOffset="0%" className="text" alignmentBaseline="middle">
                          EXPLORE MORE
                        </textPath>
                      </text>
                      <text>
                        <textPath xlinkHref="#circle" startOffset="50%" className="text" alignmentBaseline="middle">
                          EXPLORE MORE
                        </textPath>
                      </text>
                    </svg>
                  </div>
                  <div>
                    <p className="cor-paragraph">
                      Le Haim Wellness Care Provides dedicated, 24/7 Healthcare
                      Services, ensuring Physical, Emotional and Mental well
                      being. Our personalized approach enhances the quality of life.
                    </p>
                  </div>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="carousel-item">
              <img
                src="../Images/home2.png"
                alt=""
                height="700px"
                width="100%"
                style={{ borderRadius: "42px" }}
              />
              <Carousel.Caption>
                <div className="cor-image" data-aos="zoom-out-up" data-aos-duration="2000">
                  <h5 className="cor-heading">
                    Loving Care, Healthier Lives
                  </h5>

                </div>
                <div style={{ position: "relative", display: "flex" }}>
                  <div className="main" data-aos="zoom-in" data-aos-duration="2000">
                    <img src="../Images/right-arrow.png" alt="Icon" style={{ textAlign: "center" }} className="arrow-right-explore" />
                    <svg id="rotatingText" viewBox="0 0 150 150" width="150" height="150" className="rotating-text-mobile-res">
                      <defs>
                        <path id="circle" d="M 75, 75
          m -56.25, 0
          a 56.25, 56.25 0 1, 0 112.5, 0
          a 56.25, 56.25 0 1, 0 -112.5, 0">
                        </path>
                      </defs>
                      <text>
                        <textPath xlinkHref="#circle" startOffset="0%" className="text" alignmentBaseline="middle">
                          EXPLORE MORE
                        </textPath>
                      </text>
                      <text>
                        <textPath xlinkHref="#circle" startOffset="50%" className="text" alignmentBaseline="middle">
                          EXPLORE MORE
                        </textPath>
                      </text>
                    </svg>
                  </div>
                  <div>
                    <p className="cor-paragraph">
                      Le Haim Wellness Care Provides kind, 24/7 Healthcare
                      Services for the elderly, ensuring their well-being and comfort. Our personalized approach enhances the quality of life for seniors.
                    </p>
                  </div>
                </div>
              </Carousel.Caption>
            </Carousel.Item> */}
          </Carousel>
        </div>
      </div>
      {/* ======================================================================================================== */}
      <div className="card-h">
        <div className="container ">
          <div className="row">
            {Service?.map((item, index) => {
              return (
                <div className="col-md-4 mt-4">
                  <div
                    className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-b857cf6"
                    data-id="b857cf6"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-2fdbb4f elementor-widget elementor-widget-feature"
                        data-id="2fdbb4f"
                        data-element_type="widget"
                        data-widget_type="feature.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="featured-item style-1">
                            <div className="ht-shape-btn">
                              <svg
                                width="111"
                                height="111"
                                viewBox="0 0 111 111"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0 0C19.33 0 35 15.67 35 35V41C35 60.33 50.67 76 70 76H76C95.33 76 111 91.67 111 111V0H0Z"
                                  fill="none"
                                ></path>
                              </svg>
                            </div>
                            <div className="featured-number">
                              <span style={{ color: "#196b9c" }}>
                                {index + 1}
                              </span>
                            </div>
                            <div className="featured-icon">
                              <i className="flaticon flaticon-stethoscope-3">
                                <img
                                  alt=""
                                  src={`https://lehaim.in/HomeServices/${item?.serviceImage}`}
                                  width={80}
                                  className="c-img"
                                />
                              </i>
                            </div>
                            <div className="featured-desc">
                              <div className="featured-title">
                                <h4
                                  style={{ color: "#196b9c" }}
                                  className="c-head"
                                >
                                  {parse(
                                    `<div style="margin-bottom:0px">${item?.serviceName}</div>`
                                  )}
                                </h4>
                              </div>
                              <p className="c-para">
                                {parse(
                                  `<div>${item?.serviceDescription}</div>`
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            {/* <div className="col-md-4 mt-4" data-aos="zoom-in" data-aos-duration="3000">
              <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-b857cf6" data-id="b857cf6" data-element_type="column">
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div className="elementor-element elementor-element-2fdbb4f elementor-widget elementor-widget-feature" data-id="2fdbb4f" data-element_type="widget" data-widget_type="feature.default">
                    <div className="elementor-widget-container">
                      <div className="featured-item style-1">
                        <div className="ht-shape-btn">
                          <svg width="111" height="111" viewBox="0 0 111 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0 0C19.33 0 35 15.67 35 35V41C35 60.33 50.67 76 70 76H76C95.33 76 111 91.67 111 111V0H0Z" fill="none"></path>
                          </svg>
                        </div>
                        <div className="featured-number">
                          <span style={{ color: "#196b9c" }}>02</span>
                        </div>
                        <div className="featured-icon">
                          <i className="flaticon flaticon-stethoscope-3">
                            <img src="../Images/24 Hours.png" width={80} className="c-img" />
                          </i>
                        </div>
                        <div className="featured-desc">
                          <div className="featured-title">
                            <h4 style={{ color: "#196b9c" }} className="c-head">24/7 Support</h4>
                          </div>
                          <p className="c-para">We provide 24/7 support to ensure continuous care & peace of mind for our patients & their families</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-4 mt-4" data-aos="zoom-in" data-aos-duration="3000">
              <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-b857cf6" data-id="b857cf6" data-element_type="column">
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div className="elementor-element elementor-element-2fdbb4f elementor-widget elementor-widget-feature" data-id="2fdbb4f" data-element_type="widget" data-widget_type="feature.default">
                    <div className="elementor-widget-container">
                      <div className="featured-item style-1">
                        <div className="ht-shape-btn">
                          <svg width="111" height="111" viewBox="0 0 111 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0 0C19.33 0 35 15.67 35 35V41C35 60.33 50.67 76 70 76H76C95.33 76 111 91.67 111 111V0H0Z" fill="none"></path>
                          </svg>
                        </div>
                        <div className="featured-number">
                          <span style={{ color: "#196b9c" }}>03</span>
                        </div>
                        <div className="featured-icon">
                          <i className="flaticon flaticon-stethoscope-3">
                            <img src="../Images/Family-Centered Icon.png" width={80} className="c-img" />
                          </i>
                        </div>
                        <div className="featured-desc">
                          <div className="featured-title">
                            <h4 style={{ color: "#196b9c" }} className="c-head">Family-centered Care</h4>
                          </div>
                          <p className="c-para">Our family centered care focuses on involving the entire family to promote the well being of our patients.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* ====================================================================================================================== */}
      <Mainsection />
      <ScrollingText />
      <ServicesHome />

      {/* <div>
      <Suspense fallback={<div>Loading...</div>}>
        <ServicesHome />
      </Suspense>
    </div> */}

      <OurTeam />
      <LatestBlogs />
      <Testimonial />
    </div>
  );
}

export default Home;
