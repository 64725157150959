import React, { useEffect, useState } from "react";
import "../Styles/scrollingtext.css";
import axios from "axios";

const ScrollingText = () => {
  const [SlidingScroll, setSlidingScroll] = useState([]);
  console.log(SlidingScroll, "SlidingScroll");

  const getSlidingText = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/homeslidingtext/getslidingtext"
      );
      if (res.status === 201) {
        setSlidingScroll(res.data.sucess);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  useEffect(() => {
    getSlidingText();
  }, []);

  return (
    <div>
      <div className="marquee-container">
        {/* {SlidingScroll?.map((item)=>{
    return( */}
        <div className="marquee-section">
          <div className="marquee-text">
            {SlidingScroll?.map((item) => {
              return (
                <>
                  <span style={{ color: `${item?.textColor}` }}>
                    {item?.slidingText}
                  </span>
                  <i className="flaticon flaticon-stethoscope-3">
                    <img
                      src={`https://lehaim.in/SlidingText/${item?.slidingImg}`}
                      alt=""
                    />
                  </i>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrollingText;

// import React from 'react'
// import "../Styles/scrollingtext.css"

// const ScrollingText = () => {
//   return (
//     <div>
// <div className="marquee-container">
//   <div className="marquee-section">
//     <div className="marquee-text">
//       <span>Geriatric Care</span>
//       <i className="flaticon flaticon-stethoscope-3">
//         <img src='../Images/stethoscope1.png' alt='' />
//       </i>
//       <span>Post-Operative Care</span>
//       <i className="flaticon flaticon-stethoscope-3">
//         <img src='../Images/stethoscope1.png' alt='' />
//       </i>
//       <span>Palliative Care</span>
//       <i className="flaticon flaticon-stethoscope-3">
//         <img src='../Images/stethoscope1.png' alt='' />
//       </i>
//       <span>ICU Care Services</span>
//       <i className="flaticon flaticon-stethoscope-3">
//         <img src='../Images/stethoscope1.png' alt='' />
//       </i>
//       <span>Doctor Consultation</span>
//       <i className="flaticon flaticon-stethoscope-3">
//         <img src='../Images/stethoscope1.png' alt='' />
//       </i>
//       <span>Physiotherapy</span>
//       <i className="flaticon flaticon-stethoscope-3">
//         <img src='../Images/stethoscope1.png' alt='' />
//       </i>
//       <span>All Nursing Services</span>
//       <i className="flaticon flaticon-stethoscope-3">
//         <img src='../Images/stethoscope1.png' alt='' />
//       </i>
//     </div>
//     {/* <!-- Repeat the same content to ensure continuous scrolling --> */}
//     <div className="marquee-text">
//       <span>Geriatric Care</span>
//       <i className="flaticon flaticon-stethoscope-3">
//         <img src='../Images/stethoscope1.png' alt='' />
//       </i>
//       <span>Post-Operative Care</span>
//       <i className="flaticon flaticon-stethoscope-3">
//         <img src='../Images/stethoscope1.png' alt='' />
//       </i>
//       <span>Palliative Care</span>
//       <i className="flaticon flaticon-stethoscope-3">
//         <img src='../Images/stethoscope1.png' alt='' />
//       </i>
//       <span>ICU Care Services</span>
//       <i className="flaticon flaticon-stethoscope-3">
//         <img src='../Images/stethoscope1.png' alt='' />
//       </i>
//       <span>Doctor Consultation</span>
//       <i className="flaticon flaticon-stethoscope-3">
//         <img src='../Images/stethoscope1.png' alt='' />
//       </i>
//       <span>Physiotherapy</span>
//       <i className="flaticon flaticon-stethoscope-3">
//         <img src='../Images/stethoscope1.png' alt='' />
//       </i>
//       <span>All Nursing Services</span>
//       <i className="flaticon flaticon-stethoscope-3">
//         <img src='../Images/stethoscope1.png' alt='' />
//       </i>
//     </div>
//   </div>
// </div>
//     </div>
//   )
// }

// export default ScrollingText
