import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";

const AdminSlidingtext = () => {
  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delet modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [slidingImg, setSlidingImg] = useState("");
  const [slidingText, setSlidingText] = useState("");
  const [TextColor, setTextColor] = useState("");
  console.log("TextColor", TextColor);

  const SlidingText = async () => {
    try {
      const config = {
        url: "/addlidingtext",
        method: "post",
        baseURL: "https://lehaim.in/api/homeslidingtext",
        headers: { "content-type": "multipart/form-data" },
        data: {
          slidingImg: slidingImg,
          slidingText: slidingText,
          textColor: TextColor,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.status);
        handleClose3();
        setSlidingImg("");
        setSlidingText("");
        getSlidingText();
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  const [SlidingScroll, setSlidingScroll] = useState([]);
  const getSlidingText = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/homeslidingtext/getslidingtext"
      );
      if (res.status === 201) {
        setSlidingScroll(res.data.sucess);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  const [slidingTextId, setSlidingTextId] = useState("");
  const [textColorq, setextColorq] = useState("");
  const updateSlidingText = async () => {
    try {
      const config = {
        url: "/updateslidingtext/" + slidingTextId?._id,
        method: "put",
        baseURL: "https://lehaim.in/api/homeslidingtext",
        headers: { "content-type": "multipart/form-data" },
        data: {
          slidingImg: slidingImg,
          slidingText: slidingText,
          textColor: textColorq,
        },
      };
      let res = await axios(config);
      if (res.status === 201) {
        alert("Update successfully");
        handleClose4();
        getSlidingText();
        setSlidingImg("");
        setSlidingText("");
        window.location.reload(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const DeleteSlidingText = async () => {
    try {
      let res = await axios.delete(
        `https://lehaim.in/api/homeslidingtext/deleteslidingtext/${slidingTextId?._id}`
      );
      if (res.status === 201) {
        alert(res.data.message);
        getSlidingText();
        handleClose5();
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  };
  useEffect(() => {
    getSlidingText();
  }, []);

  console.log("SlidingScroll", SlidingScroll);

  return (
    <div>
      {/* <div className="col-lg-4 d-flex justify-content-center">
        <div class="input-group ">
          <span class="input-group-text" id="basic-addon1">
            <BsSearch />
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="Search..."
            aria-describedby="basic-addon1"
          />
        </div>
      </div> */}

      <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Service Name</h2>
          <div className="d-flex gap-3">
            <button className="admin-add-btn" onClick={handleShow3}>
              Add Service Name
            </button>
          </div>
        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Service Image</th>
                <th>Service Text</th>
                <th>Text Color</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {SlidingScroll?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      <Image
                        src={`https://lehaim.in/SlidingText/${item?.slidingImg}`}
                        alt="pic"
                        style={{ width: "75px", height: "75px" }}
                      />
                    </td>
                    <td style={{ paddingTop: "10px" }}>{item?.slidingText}</td>
                    <td style={{ paddingTop: "10px" }}>{item?.textColor}</td>

                    <td>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <BiSolidEdit
                            className=""
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "rgb(101, 177, 69)",
                            }}
                            onClick={() => {
                              handleShow4();
                              setSlidingTextId(item);
                            }}
                          />{" "}
                        </div>
                        <div>
                          <AiFillDelete
                            className=""
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "rgb(25, 107, 156)",
                            }}
                            onClick={() => {
                              handleShow5();
                              setSlidingTextId(item);
                              // setDatas(item?._id);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* Add Package modal for Slider */}
        <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
          <Modal.Header closeButton>
            <Modal.Title>Add Service Name</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Service Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setSlidingImg(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Service Text</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Service Text"
                  onChange={(e) => setSlidingText(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Text Color</label>
                <input
                  type="color"
                  className="vi_0"
                  placeholder="Enter Service Text Color"
                  onChange={(e) => setTextColor(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={SlidingText}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal for Slider */}
        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              Edit Service Name
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Service Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setSlidingImg(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Service Text</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Service Text"
                  onChange={(e) => setSlidingText(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Text Color</label>
                <input
                  type="color"
                  className="vi_0"
                  placeholder="Enter Service Text Color"
                  value={textColorq}
                  onChange={(e) => setextColorq(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={updateSlidingText}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Delet Package modal for Slider */}
        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={DeleteSlidingText}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AdminSlidingtext;
