import logo from "./logo.svg";
import "./App.css";

import Header from "./Components/Header";
import HeaderResponsive from "./Components/HeaderResponsive";
import Home from "./Components/Home";
// import Footer from './Components/Footer';
import About from "./Components/About";
import Faq from "./Components/Faq";
import { Blog } from "./Components/Blog";
import Service from "./Components/Service";
import Gallery from "./Components/Gallery";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { NephrologistCare } from "./Components/NephrologistCare";

// import 'bootstrap/dist/css/bootstrap.min.css';

import Main from "./Components/Admin/Main";

//import Dashboard from "./Components/Admin/Dashboard";

import Login from "./Components/Login";
import SignUp from "./Components/SignUp";

//import HomeBanner from "./Components/Admin/HomeBanner";

import AdminSlider from "./Components/Admin/AdminSlider";
import AdminServices from "./Components/Admin/AdminServices";
import AdminWhyChooseus from "./Components/Admin/AdminWhyChooseus";
import AdminSlidingtext from "./Components/Admin/AdminSlidingtext";
import AdminDepartment from "./Components/Admin/AdminDepartment";
import AdminTestimonial from "./Components/Admin/AdminTestimonial";
import AdminOurspecialist from "./Components/Admin/AdminOurspecialist";

import AdminAboutbanner from "./Components/Admin/AdminAboutbanner";
import AdminAboutcaterory from "./Components/Admin/AdminAboutcaterory";
import AdminAboutwhoweare from "./Components/Admin/AdminAboutwhoweare";

import AdminServicebanner from "./Components/Admin/AdminServicebanner";
import AdminServiceourservices from "./Components/Admin/AdminServiceourservices";

import AdminGallerybanner from "./Components/Admin/AdminGallerybanner";
import AdminGalleryimage from "./Components/Admin/AdminGalleryimage";
import AdminGalleryvideo from "./Components/Admin/AdminGalleryvideo";

import AdminBlogbanner from "./Components/Admin/AdminBlogbanner";
import AdminBlogdescription from "./Components/Admin/AdminBlogdescription";
import AdminBlogevents from "./Components/Admin/AdminBlogevents";

import AdminFaqbanner from "./Components/Admin/AdminFaqbanner";
import AdminFaq from "./Components/Admin/AdminFaq";

import AdminContactbanner from "./Components/Admin/AdminContactbanner";
import AdminContactdetails from "./Components/Admin/AdminContactdetails";
import AdminContactsocialmedia from "./Components/Admin/AdminContactsocialmedia";
import AdminContactgeneralenquiry from "./Components/Admin/AdminContactgeneralenquiry";

//vidya service

import Admin_Service_Details from "./Components/Admin/Admin_Service_Details";
import AllBlogs from "./Components/AllBlogs";
import AdminPrivacypolicy from "./Components/Admin/AdminPrivacypolicy";
import AdminTermsAndConditions from "./Components/Admin/AdminTermsAndConditions";
import AdminPatientsCharter from "./Components/Admin/AdminPatientsCharter";
import FooterPrivacyPolicy from "./Components/FooterPrivacyPolicy";
import FooterTermsAndConditions from "./Components/FooterTermsAndConditions";
import FooterPatientCharter from "./Components/FooterPatientCharter";
import Whatsapp from "./Components/Whatsapp";
import Testimonial from "./Components/Testimonial";
import DocTestimonial from "./Components/Admin/DocTestimonial";
import AdminEmail from "./Components/Admin/AdminEmail";
// import Footer from './Components/Admin/Footer'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <HeaderResponsive />
                <Home />
                <Whatsapp />
                <Footer />
              </>
            }
          />

          <Route
            path="/about-s"
            element={
              <>
                <Header />
                <HeaderResponsive />
                <About />
                <Whatsapp />
                <Footer />
              </>
            }
          />

          <Route
            path="/service"
            element={
              <>
                <Header />
                <HeaderResponsive />
                <Service />
                <Whatsapp />
                <Footer />
              </>
            }
          />

          <Route
            path="/nephrologistcare"
            element={
              <>
                <Header />
                <HeaderResponsive />
                <NephrologistCare />
                <Whatsapp />
                <Footer />
              </>
            }
          />

          <Route
            path="/gallery"
            element={
              <>
                <Header />
                <HeaderResponsive />
                <Gallery />
                <Whatsapp />
                <Footer />
              </>
            }
          />

          <Route
            path="/contact"
            element={
              <>
                <Header />
                <HeaderResponsive />
                <Contact />
                <Whatsapp />
                <Footer />
              </>
            }
          />

          <Route
            path="/faq"
            element={
              <>
                <Header />
                <HeaderResponsive />
                <Faq />
                <Whatsapp />
                <Footer />
              </>
            }
          />

          <Route
            path="/blog"
            element={
              <>
                <Header />
                <HeaderResponsive />
                <Blog />
                <Whatsapp />
                <Footer />
              </>
            }
          />

          <Route
            path="/allblog"
            element={
              <>
                <Header />
                <HeaderResponsive />
                <AllBlogs />
                <Whatsapp />
                <Footer />
              </>
            }
          />

          <Route
            path="/footer_privacypolicy"
            element={
              <>
                <Header />
                <HeaderResponsive />
                <FooterPrivacyPolicy />
                <Whatsapp />
                <Footer />
              </>
            }
          />

          <Route
            path="/footer_termsandconditions"
            element={
              <>
                <Header />
                <HeaderResponsive />
                <FooterTermsAndConditions />
                <Whatsapp />
                <Footer />
              </>
            }
          />

          <Route
            path="/footer_patientscharter"
            element={
              <>
                <Header />
                <HeaderResponsive />
                <FooterPatientCharter />
                <Whatsapp />
                <Footer />
              </>
            }
          />

          {/* login */}

          <Route
            path="/login"
            element={
              <>
                <Login />
              </>
            }
          />

          {/* signup */}

          <Route
            path="/signup"
            element={
              <>
                <SignUp />
              </>
            }
          />

          {/* <Route
            path="/dashboard"
            element={
              <Main
                children={
                  <>
                    <Dashboard />
                  </>
                }
              />
            }
          /> */}

          <Route
            path="/admin_slider"
            element={
              <Main
                children={
                  <>
                    <AdminSlider />
                  </>
                }
              />
            }
          />
          <Route
            path="/admin_email"
            element={
              <Main
                children={
                  <>
                    <AdminEmail />
                  </>
                }
              />
            }
          />
          <Route
            path="/admin_doc-testimonial"
            element={
              <Main
                children={
                  <>
                    <DocTestimonial />
                  </>
                }
              />
            }
          />
          {/* <Route
            path="/admin_homeslider"
            element={
              <Main
                children={
                  <>
                   <HomeBanner/>
                  </>
                }
              />
            }
          /> */}

          <Route
            path="/admin_service"
            element={
              <Main
                children={
                  <>
                    <AdminServices />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_whychooseus"
            element={
              <Main
                children={
                  <>
                    <AdminWhyChooseus />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_sliding-text"
            element={
              <Main
                children={
                  <>
                    <AdminSlidingtext />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_department"
            element={
              <Main
                children={
                  <>
                    <AdminDepartment />
                  </>
                }
              />
            }
          />

          <Route
            path="/Admin_Service_Details"
            element={
              <Main
                children={
                  <>
                    <Admin_Service_Details />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_ourspecialist"
            element={
              <Main
                children={
                  <>
                    <AdminOurspecialist />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_testimonials"
            element={
              <Main
                children={
                  <>
                    <AdminTestimonial />
                  </>
                }
              />
            }
          />

          {/* ABOUT */}
          <Route
            path="/admin_aboutbanner"
            element={
              <Main
                children={
                  <>
                    <AdminAboutbanner />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_aboutcaterorybanner"
            element={
              <Main
                children={
                  <>
                    <AdminAboutcaterory />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_aboutwhoweare"
            element={
              <Main
                children={
                  <>
                    <AdminAboutwhoweare />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_servicebanner"
            element={
              <Main
                children={
                  <>
                    <AdminServicebanner />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_serviceourservice"
            element={
              <Main
                children={
                  <>
                    <AdminServiceourservices />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_gallerybanner"
            element={
              <Main
                children={
                  <>
                    <AdminGallerybanner />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_galleryimage"
            element={
              <Main
                children={
                  <>
                    <AdminGalleryimage />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_galleryvideo"
            element={
              <Main
                children={
                  <>
                    <AdminGalleryvideo />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_blogbanner"
            element={
              <Main
                children={
                  <>
                    <AdminBlogbanner />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_blogdescription"
            element={
              <Main
                children={
                  <>
                    <AdminBlogdescription />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_blogevents"
            element={
              <Main
                children={
                  <>
                    <AdminBlogevents />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_faqbanner"
            element={
              <Main
                children={
                  <>
                    <AdminFaqbanner />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_faq"
            element={
              <Main
                children={
                  <>
                    <AdminFaq />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_contactbanner"
            element={
              <Main
                children={
                  <>
                    <AdminContactbanner />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_contactinformation"
            element={
              <Main
                children={
                  <>
                    <AdminContactdetails />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_contactsocialmedia"
            element={
              <Main
                children={
                  <>
                    <AdminContactsocialmedia />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_contactgeneralenquiry"
            element={
              <Main
                children={
                  <>
                    <AdminContactgeneralenquiry />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_privacypolicy"
            element={
              <Main
                children={
                  <>
                    <AdminPrivacypolicy />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_termsandconditions"
            element={
              <Main
                children={
                  <>
                    <AdminTermsAndConditions />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_patientscharter"
            element={
              <Main
                children={
                  <>
                    <AdminPatientsCharter />
                  </>
                }
              />
            }
          />
        </Routes>
      </BrowserRouter>

      {/* <Footer/> */}
    </div>
  );
}

export default App;
